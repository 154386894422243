/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  Avatar,
  Box,
  CircularProgress,
  createTheme,
  Menu,
  MenuItem,
  ThemeProvider,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import Template from '../../../utils/api/templates';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import {
  getCurrentTemplate,
  savedTemplateList,
  updateCurrentTemplate,
  setPage,
  updateSavedTemplate,
  clearPageCount,
} from '../../../redux/slices/TemplateSlice';
import {
  MoreMenuIconHorizontal,
  EditSavedTemplateNameIcon,
} from '../../../shared/component/svgIcon';
import {
  capitalize, changeDateTimeFormat, checkFeatures, checkPermission,
} from '../../../utils/helpers';
import SearchBox from '../../../shared/component/form/SearchBox';

function SavedTemplates() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const userName = JSON.parse(localStorage.getItem('data'));
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [closeModal, setCloseModal] = useState(true);
  const [editedTemplate, setEditedTemplate] = useState({});
  const [searchTemplate, setSearchTemplate] = useState('');
  const [inputActive, setInputActive] = useState(false);
  const open = Boolean(anchorEl);

  const {
    savedTemplateData,
    pageCount,
    totalPageCount,
    status,
  } = useSelector((state) => state?.currentTemplate);

  const theme = createTheme({
    components: {
      MuiPopover: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 1px 12px 4px #00000014 !important',
            width: 'inherit',
          },
        },
      },
    },
  });

  const getSavedTemplates = (counts) => {
    if (status === 'succeeded') {
      dispatch(savedTemplateList({ page: counts, limit: 20 }));
    }
  };

  useEffect(() => {
    if (pageCount === 0) {
      getSavedTemplates(1);
    }

    return () => {
      dispatch(clearPageCount(0));
    };
  }, []);

  const handleScroll = () => {
    getSavedTemplates(pageCount + 1);
  };

  const permissionError = {
    message: 'view permission denied',
  };

  const handleNavigateHtml = (data) => {
    if ((!checkPermission('getTemplatesList'))
      || ((!checkFeatures('templates', 'view')))) {
      dispatch(setErrorNotification(permissionError));
    }
    if (checkPermission('getTemplatesList') && checkFeatures('templates', 'view')) {
      navigate(`/admin/templates/view-image/${data?.template_number}`);
      dispatch(getCurrentTemplate(data));
      dispatch(setPage('tab-2'));
    }
  };

  const handleDropdownClick = (event, template, index) => {
    setAnchorEl(event.currentTarget);
    setEditedTemplate(template);
    setSelectedIndex(index);
  };
  const handleClose = (data) => {
    setAnchorEl(null);
    setSelectedIndex(null);
    setInputActive(false);
    const postData = [
      {
        _id: data?._id,
        multi_pricing: data?.multi_pricing,
        search_tags: data?.search_tags,
        styles: data?.styles,
        tags: data?.tags,
        template_backgroundImage_url: data?.template_backgroundImage_url,
        template_contents: data?.template_contents,
        template_image_url: data?.template_image_url,
        template_images: data?.template_images,
        template_name: data?.template_name,
        template_number: data?.template_number,
        template_type: data?.template_type,
        template_title: data?.template_title,
        clone: data?.clone,
        content_id: data?.content_id,
      },
    ];
    if (inputActive) {
      Template?.updateSavedTemplate(postData)?.then((res) => {
        dispatch(updateCurrentTemplate({ ...res?.data }));
      })
        .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    }
  };
  const handleEditClick = (data) => {
    if (checkPermission('getTemplatesList')
      && checkFeatures('templates', 'edit') && closeModal) {
      navigate(`/admin/templates/edit-content/${data?.template_number}`);
      dispatch(getCurrentTemplate(data));
      dispatch(setPage('tab-2'));
    }
  };
  const handleDeleteClick = (id) => {
    if (checkPermission('getTemplatesList')
      && checkFeatures('templates', 'delete') && closeModal) {
      Template?.deleteSavedTemplates(id)
        .then(() => {
          const newData = JSON.parse(JSON.stringify(savedTemplateData));
          newData.splice(selectedIndex, 1);
          dispatch(updateSavedTemplate(newData));
        })
        .catch((error) => dispatch(setErrorNotification(error?.response?.data)));
      setAnchorEl(null);
    }
  };
  const handleEditName = (e) => {
    if (checkPermission('getTemplatesList')
      && checkFeatures('templates', 'edit')) {
      const newData = JSON.parse(JSON.stringify(savedTemplateData));
      if (e.target.value.length >= 0) {
        newData[selectedIndex].template_name = e.target.value;
        setCloseModal(true);
      } if (e.target.value.length === 0 || e.target.value.length > 25) {
        setCloseModal(false);
      }
      setEditedTemplate(newData[selectedIndex]);
      dispatch(updateSavedTemplate(newData));
    } else {
      dispatch(setErrorNotification(permissionError));
    }
  };
  const handleCloneClick = (clone) => {
    if (closeModal) {
      const cloned = JSON.parse(JSON.stringify(clone));
      cloned.clone = true;
      setAnchorEl(null);
      setCloseModal(true);
      dispatch(getCurrentTemplate(cloned));
      dispatch(setPage('tab-2'));
      navigate(`/admin/templates/edit-content/${clone?.template_number}`);
    }
  };

  const handleChangeSearchTemplate = (name, changeValue) => {
    setSearchTemplate(changeValue);
    dispatch(savedTemplateList({ name: changeValue, tag: changeValue }));
  };
  const handleEditIcon = () => {
    setInputActive(true);
  };
  return (
    <div className="saved-templates">
      <div className="search-template">
        <SearchBox
          icon={' '}
          type="text"
          name="searchTemplate"
          value={searchTemplate}
          change={handleChangeSearchTemplate}
          placeholder={t('searchtemplate')}
        />
      </div>
      <InfiniteScroll
        dataLength={savedTemplateData?.length}
        next={handleScroll}
        height="100vh"
        hasMore={pageCount < totalPageCount}
        loader={status === 'loading' && (
          <h4 className="text-center">
            <Box sx={{ display: 'flex' }}>
              <CircularProgress color="success" />
            </Box>
          </h4>
        )}
      >
        <div className="s_template-list">
          {savedTemplateData?.map((template, tempIndex) => (
            <div className="s_template-grid" key={template?._id}>
              <div className="s_template-grid-img">
                <div
                  role="presentation"
                  className="images"
                  onClick={() => handleNavigateHtml(template)}
                >
                  <img
                    src={template?.signedUrl ? template?.signedUrl : template?.template_image_url}
                    alt="templateImage"
                    loading="lazy"
                    style={{
                      objectFit: (template?.template_type === 'Bar Display'
                        || template?.template_type === 'Shelf Display')
                        ? 'contain'
                        : 'inherit',
                    }}
                  />
                </div>
              </div>

              <div className="s_template-footer">
                <div className="avatar">
                  <div className="avatar">
                    <Avatar className="avatar-img-tx">{capitalize(userName?.user?.name?.charAt(0))}</Avatar>
                    <p>
                      {template?.template_name}
                    </p>
                  </div>
                  <div onClick={(event) => handleDropdownClick(event, template, tempIndex)} role="presentation">
                    <MoreMenuIconHorizontal />
                  </div>
                  <ThemeProvider theme={theme}>
                    <Menu
                      id={`menu-${tempIndex}`}
                      anchorEl={anchorEl}
                      open={open && selectedIndex === tempIndex}
                      onClose={closeModal && (() => handleClose(editedTemplate))}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        key={`menuitem-${selectedIndex}-edit-input`}
                      >
                        <Box sx={{
                          display: 'flex',
                        }}
                        >
                          {
                            !inputActive && <span>{template?.template_name}</span>
                          }
                          {inputActive
                            && (
                              <input
                                value={template?.template_name}
                                style={{ border: 'none', outline: 'none' }}
                                disabled={!checkPermission('getTemplatesList')
                                  || !checkFeatures('templates', 'edit')}
                                onChange={(e) => handleEditName(e, editedTemplate)}
                                onKeyDown={(e) => e.stopPropagation()}
                              />
                            )}
                          <Box
                            sx={{ margin: inputActive ? '5px 0px 0px 20px' : '3px 0px 0px 10px' }}
                            onClick={handleEditIcon}
                            role="none"
                          >
                            <EditSavedTemplateNameIcon />
                          </Box>
                        </Box>
                      </MenuItem>
                      {
                        !closeModal
                        && (
                          <Box sx={
                            {
                              margin: '0', padding: '0px', color: 'red', fontSize: '11px', textAlign: 'center',
                            }
                          }
                          >
                            <span>
                              Template Name cannot be empty and <br />
                              must be no more than 25 characters
                            </span>
                          </Box>
                        )
                      }
                      <Box sx={{ border: '0.1px solid #CED0D3', width: '100%' }} />
                      <MenuItem
                        key={`menuitem-${selectedIndex}-edit`}
                        sx={{
                          paddingX: '20px',
                          paddingY: '10px',
                        }}
                        onClick={() => handleEditClick(editedTemplate)}
                        role="presentation"
                        disabled={!checkPermission('getTemplatesList') || !checkFeatures('templates', 'edit')}
                      >
                        {t('Edit')}
                      </MenuItem>
                      <MenuItem
                        key={`menuitem-${selectedIndex}-clone`}
                        sx={{
                          paddingX: '20px',
                          paddingY: '10px',
                        }}
                        onClick={() => handleCloneClick(editedTemplate)}
                      >
                        {t('cloneTemplate')}
                      </MenuItem>
                      <MenuItem
                        key={`menuitem-${selectedIndex}-delete`}
                        sx={{
                          paddingX: '20px',
                          paddingY: '10px',
                          color: 'red',
                        }}
                        onClick={() => handleDeleteClick(editedTemplate?._id)}
                        disabled={!checkPermission('getTemplatesList')
                          || !checkFeatures('templates', 'delete')}
                        role="presentation"
                      >
                        {t('Delete')}
                      </MenuItem>
                    </Menu>
                  </ThemeProvider>
                </div>
                <div className="created-date">
                  <span>{changeDateTimeFormat(template?.updatedAt)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
}

export default SavedTemplates;
