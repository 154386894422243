/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import subscription from '../../utils/api/subscription';

import {
  changeDateFormat,
  changeDateTimeFormat,
  currencySymbol,
} from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchSubscriptionList = createAsyncThunk(
  'subscriptions/fetchSubscriptions',
  async (data, { dispatch }) => {
    const response = await subscription.getSubscriptionHistory(data).then((response1) => {
      const tableData = response1?.data?.results.map((val) => ({
        ...val,
        order_id: val?.subscription?.order_id,
        devices_count: val?.device?.count,
        plan_name: val?.plan?.name,
        price: `${currencySymbol(val?.subscription?.currency || 'USD')} ${val.total_price}`,
        storage: `${val?.summary?.storage} (${val?.addon?.storage?.count || 0})`,
        request_date: changeDateTimeFormat(val?.createdAt),
        plan_duration: `${changeDateFormat(val?.start_date)} to ${changeDateFormat(val?.end_date)}`,
        devices: `${val?.summary?.device} (${val?.device?.count || 0})`,
        id: val._id,
      }));
      return { ...response1?.data, results: tableData, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

export const fetchTransactionList = createAsyncThunk(
  'subscriptions/fetchTransaction',
  async (data, { dispatch }) => {
    const response = await subscription.getTransactionHistory(data).then((response1) => {
      const tableData = response1?.data?.results.map((val) => ({
        ...val,
        order_id: val?.subscription?.order_id,
        devices_count: val?.device?.count,
        transaction_id: val?.transaction_details?.transaction_id,
        type: val?.transaction_details?.transaction_type,
        price: `${currencySymbol(val.currency || 'USD')} ${val.total_price}`,
        plan_name: val?.plan?.name,
        storage: val?.subscription?.storage,
        request_date: val?.transaction_details ? changeDateFormat(val?.transaction_details?.transaction_date) : '',
        plan_duration: `${changeDateFormat(val?.start_date)} to ${changeDateFormat(val?.end_date)}`,
        id: val._id,
      }));
      return { ...response1?.data, results: tableData, code: 200 };
    }).catch((error) => {
      dispatch(setErrorNotification(error?.response?.data));
    });
    return response;
  },
);

const initialState = {
  subscriptions: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  filter: {},
  tableFilter: {},

  transaction: [],
  pageCountTransaction: 0,
  totalPageCountTransaction: 1,
  totalDataCountTransaction: 0,
  filterTransaction: {},
  tableFilterTransaction: {},

  status: 'succeed',
};

export const subscriptionSlice = createSlice({
  name: 'mySchedule',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setTransactionFilter: (state, action) => {
      state.filterTransaction = action.payload;
    },
    setTransactionTableFilter: (state, action) => {
      state.tableFilterTransaction = action.payload;
    },
    resetSubscriptionState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchSubscriptionList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSubscriptionList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          const datas = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.subscriptions = datas;
          } else {
            state.subscriptions.push(...datas);
          }
        }
      })
      .addCase(fetchSubscriptionList.rejected, (state) => {
        state.status = 'failed';
      });

    builder
      .addCase(fetchTransactionList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactionList.fulfilled, (state, action) => {
        // Add user to the state array
        state.status = 'succeed';
        if (action?.payload?.code === 200) {
          const datas = action.payload.results;
          // if (datas.length > 0) {
          state.pageCountTransaction = action.payload.page;
          state.totalPageCountTransaction = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCountTransaction = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.transaction = datas;
          } else {
            state.transaction.push(...datas);
          }
          // }
        }
      })
      .addCase(fetchTransactionList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  setFilter,
  setTableFilter,
  setTransactionFilter,
  setTransactionTableFilter,
  resetSubscriptionState,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
