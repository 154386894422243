/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import ImageMarker from 'react-image-marker';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toPng } from 'html-to-image';
import {
  CloseIconWithoutBorder,
  DeviceIcon,
  BackButton,
} from '../../../shared/component/svgIcon';
import displaySignage from '../../../utils/api/devices';
import { Input } from '../../../shared/component/form/Input';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { getResolutionCategory } from '../../../utils/helpers';
import Button from '../../../shared/component/Button';

function CustomMarker(props) {
  return (
    <div className="custom-marker">
      <Tooltip title={props?.name}>
        <span
          role="presentation"
          onClick={props?.updateFunction}
        >
          <DeviceIcon />
        </span>
      </Tooltip>
    </div>
  );
}

function FloorMapDeviceView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const popupRef = useRef(null);
  const floorMapRef = useRef(null);

  const { singleStoreData } = useSelector((state) => state.store);
  const floorMapData = singleStoreData.floor_map[params.id];

  const selectedDeviceDetailsToShow = [
    {
      name: 'Brand',
      key: 'brand',
    },
    {
      name: 'Device OS',
      key: 'hardware',
    },
    {
      name: 'Size',
      key: 'size',
    },
    {
      name: 'Resolution',
      key: 'resolution',
    },
    {
      name: 'Orientation',
      key: 'orientation',
    },
    {
      name: 'Storage',
      key: 'storage',
    },
  ];

  const [popupPosition, setPopupPosition] = useState(null);
  const [displayList, setDisplayList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState({});
  const [markers, setMarkers] = useState([]);

  // Function to close device add popup
  const handleClosePopup = () => {
    setSelectedDevice(null);
    setSelectedDeviceDetails({});
    setPopupPosition(null);
  };

  // Function to update the marked device
  const handleUpdateMarkedDevice = (marker) => {
    setSelectedDevice(marker);
    setPopupPosition({ x: marker.top, y: marker.left });
  };

  // Download floor map (Convert html to image)
  const downloadSingleFloorMap = () => {
    if (!floorMapRef.current) return;

    toPng(floorMapRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${floorMapData?.name}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (singleStoreData) {
      displaySignage.getDevicesAll()
        .then((res) => {
          const deviceList = res?.data?.map((display) => ({
            ...display,
            id: display?._id,
            label: display?.name,
            storage: `${display?.device_total_storage} GB`,
            resolution: display?.display_width_pixels
              && getResolutionCategory(display?.display_width_pixels, display?.display_height_pixels),
          }));
          setDisplayList([
            {
              id: 0,
              label: 'None',
            },
            ...deviceList,
          ]);
        })
        .catch((err) => {
          dispatch(setErrorNotification(err?.response?.data));
        });
    } else {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    setMarkers(floorMapData?.layout?.map((markedDevice) => ({
      ...markedDevice,
      top: markedDevice.x,
      left: markedDevice.y,
    })));
  }, [floorMapData]);

  useEffect(() => {
    if (selectedDevice?.device) {
      setSelectedDeviceDetails(displayList.find((device) => device.id === selectedDevice.device));
    }
  }, [selectedDevice]);

  return (
    <div className="floor-map-wrap">
      <div className="floor-map-header">
        <div className="floor-header-left">
          <span
            role="presentation"
            onClick={() => navigate(-1)}
          >
            <BackButton />
          </span>
          <h2>{floorMapData?.name}</h2>
        </div>
        <Button
          label="Download"
          click={downloadSingleFloorMap}
          classes="success-button loading-btn"
          disabled={false}
        // loading={uploading}
        />
      </div>
      <div className="floor-map-container" ref={floorMapRef}>
        <ImageMarker
          src={floorMapData?.signedUrl}
          markers={markers}
          onAddMarker={() => { }}
          markerComponent={
            (marker) => <CustomMarker {...marker} updateFunction={() => handleUpdateMarkedDevice(marker)} />
          }
        />
        {popupPosition && (
          <div
            ref={popupRef}
            className="popup-wrap"
            style={{
              top: popupPosition.x <= 50 && `${popupPosition.x}%`,
              bottom: popupPosition.x > 50 && `${100 - popupPosition.x}%`,
              left: popupPosition.y <= 50 && `${popupPosition.y}%`,
              right: popupPosition.y > 50 && `${100 - popupPosition.y}%`,
            }}
          >
            <div className="popup-header">
              <h4>Mark Device</h4>
              <span
                role="presentation"
                onClick={handleClosePopup}
              >
                <CloseIconWithoutBorder />
              </span>
            </div>
            <div className="popup-container">
              <Input
                label=""
                name="device"
                value={selectedDeviceDetails?.name}
                readonly
                id="select-roles"
                placeholder="Select Device"
              />
              {
                selectedDeviceDetails
                && (
                  <div className="device-details">
                    {selectedDeviceDetailsToShow.map((detail) => {
                      let value;
                      if (selectedDeviceDetails[detail.key]) {
                        value = (
                          <div className="single-detail">
                            <h5>{detail.name}</h5>
                            <h6>{selectedDeviceDetails[detail.key]}</h6>
                          </div>
                        );
                      }
                      return value;
                    })}
                  </div>
                )
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

CustomMarker.propTypes = {
  name: PropTypes.string.isRequired,
  updateFunction: PropTypes.func.isRequired,
};

export default FloorMapDeviceView;
