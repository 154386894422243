/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '../../../shared/component/Button';
import PopupModal from '../../../shared/component/PopupModel';

import {
  fetchDisplaySignageList,
  setFilter as setDeviceFilter,
  setTableFilter as setDeviceTableFilter,
} from '../../../redux/slices/ScheduleDisplaySignageSlice';
import {
  fetchDisplayGroupList,
  setFilter as setDeviceGroupFilter,
  setTableFilter as setDeviceGroupTableFilter,
} from '../../../redux/slices/ScheduleDisplayGroupSlice';
import DataTable from '../../Table';
import { ApplicableTickIcon } from '../../../shared/component/svgIcon';

function DeviceList(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedContents, setSelectedContents] = useState([]);
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);

  // Selector and state variables for device
  const {
    displaySignage,
    pageCount: devicePageCount,
    totalPageCount: deviceTotalPageCount,
    totalDataCount: deviceTotalDataCount,
    filter: deviceFilter,
    tableFilter: deviceTableFilter,
    status: deviceStatus,
  } = useSelector((state) => state.scheduleDisplaySignage);

  const [deviceSelection, setDeviceSelection] = useState([]);
  const [deviceColumnVisibility, setDeviceColumnVisibility] = useState([]);

  // Selector and state variables for device group
  const {
    displayGroup,
    pageCount: deviceGroupPageCount,
    totalPageCount: deviceGroupTotalPageCount,
    totalDataCount: deviceGroupTotalDataCount,
    tableFilter: deviceGroupTableFilter,
    filter: deviceGroupFilter,
    status: deviceGroupStatus,
  } = useSelector((state) => state.scheduleDisplayGroup);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [deviceGroupSelection, setDeviceGroupSelection] = useState([]);
  const [deviceGroupColumnVisibility, setDeviceGroupColumnVisibility] = useState([]);

  // Common state
  const [deviceGroupToggle, setDeviceGroupToggle] = useState();
  const [disable, setDisable] = useState(true);
  // State to hold selected device and device group
  const [selectedValues, setSelectedValues] = useState({
    devices: props?.values?.devices,
    devices_list: props?.values?.devices_list || [],
    device_groups: props?.values?.device_groups,
    device_groups_list: props?.values?.device_groups_list || [],
  });

  // Device table columns
  const deviceColumn = [
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('storeInfo'),
      accessorKey: 'store',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.store_name,
    },
    {
      header: t('facilities'), // Need to add an arabic word
      accessorKey: 'storeLocation',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => row.original.store.city,
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation_flag',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <div>
          {row.original.orientation_flag ? <ApplicableTickIcon /> : 'Not Applicable'}
        </div>
      ),
    },
    {
      header: t('version'),
      accessorKey: 'version_flag',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <div>
          {row.original.version_flag ? <ApplicableTickIcon /> : 'Not Applicable'}
        </div>
      ),
    },
  ];

  // Device Group table columns
  const deviceGroupColumn = [
    {
      header: t('deviceGroups'),
      accessorKey: 'name',
    },
    {
      header: t('totalDevices'),
      accessorKey: 'count',
      filterFn: 'commonFIlterFn',
      enableSorting: false,
      muiFilterTextFieldProps: { type: 'number' },
      Cell: ({ row }) => (
        <div>
          {row.original.devices_list
            && (
              <PopupModal list={row.original.devices_list} name="devices" />
            )}
          {row.original.devices && !row.original.devices_list
            && (
              <span>{row.original.devices?.length}</span>
            )}
        </div>
      ),
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation_flag',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <div>
          {row.original.orientation_flag ? <ApplicableTickIcon /> : 'Not Applicable'}
        </div>
      ),
    },
    {
      header: t('version_flag'),
      accessorKey: 'version_flag',
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <div>
          {row.original.version_flag ? <ApplicableTickIcon /> : 'Not Applicable'}
        </div>
      ),
    },
  ];

  // Function to handle dispatch both device and device groups
  const getNextPageData = (count, search) => {
    if (deviceGroupToggle) {
      dispatch(fetchDisplayGroupList({
        orientation: props?.layout?.orientation,
        contents: selectedContents,
        playlists: selectedPlaylists,
        page: count,
        limit: 20,
        ...search,
      }));
    } else {
      dispatch(fetchDisplaySignageList({
        orientation: props?.layout?.orientation,
        contents: selectedContents,
        playlists: selectedPlaylists,
        page: count,
        limit: 20,
        ...search,
      }));
    }
  };

  // Load more data when user scroll
  const handleScroll = () => {
    if (deviceGroupToggle) {
      getNextPageData(deviceGroupPageCount + 1, deviceGroupFilter);
    } else {
      getNextPageData(devicePageCount + 1, deviceFilter);
    }
  };

  // Handle back click
  const back = () => {
    props?.closeModal(false);
  };

  // Function to Handle submit
  const submit = () => {
    props?.selectData('devices', selectedValues);
  };

  // Convert the selected device from an object to array
  useEffect(() => {
    // Filter and push the selected devices into selectedValues.devices_list
    const selectedDevices = displaySignage.filter((device) => Object.keys(deviceSelection).includes(device.id));
    setSelectedValues({
      ...selectedValues,
      devices: Object.keys(deviceSelection) || [],
      devices_list: selectedDevices,
    });
  }, [deviceSelection, displaySignage]);

  // Convert the selected device group from an object to array
  useEffect(() => {
    // Filter and push the selected devices group into selectedValues.device_groups_list
    const selectedDeviceGroups = displayGroup.filter((device) => Object.keys(deviceGroupSelection).includes(device.id));
    setSelectedValues({
      ...selectedValues,
      device_groups: Object.keys(deviceGroupSelection) || [],
      device_groups_list: selectedDeviceGroups,
    });
  }, [deviceGroupSelection, displayGroup]);

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    if (deviceGroupToggle) {
      dispatch(setDeviceGroupTableFilter(reduxColumnFiltersSorting));
      dispatch(setDeviceGroupFilter(filteredValues));
      getNextPageData(1, filteredValues);
    } else {
      dispatch(setDeviceTableFilter(reduxColumnFiltersSorting));
      dispatch(setDeviceFilter(filteredValues));
      getNextPageData(1, filteredValues);
    }
  };

  const handleUpdatedContentList = () => {
    if (props?.layout) {
      const contentList = [];
      const playlistList = [];

      props?.layout?.layouts?.forEach((eachLayout) => {
        const { contents = [], playlists = [] } = eachLayout;

        if (props?.layout?.oldLayout) {
          if (contents?.length > 0) contentList?.push(...contents);
          if (playlists?.length > 0) playlistList?.push(...playlists);
        } else {
          contents?.forEach((content) => {
            const contentId = content?._id;
            if (content?.file_type === 'playlist' && contentId) {
              playlistList?.push(contentId);
            } else if (contentId) {
              contentList?.push(contentId);
            }
          });
        }
      });

      setSelectedContents(contentList);
      setSelectedPlaylists(playlistList);
    } else {
      setSelectedContents(props?.contents?.map((content) => content?._id) || []);
      setSelectedPlaylists(props?.playlists?.map((playlist) => playlist?._id) || []);
    }
  };

  useEffect(() => {
    setIsFirstRender(false);
    if (deviceGroupPageCount === 0
      && Object.keys(deviceGroupTableFilter).length === 0
      && deviceGroupToggle
      && (selectedContents?.length > 0
        || selectedPlaylists?.length > 0)
    ) {
      getNextPageData(1, {});
    } else if (devicePageCount === 0
      && Object.keys(deviceTableFilter).length === 0
      && (selectedContents?.length > 0
        || selectedPlaylists?.length > 0)
    ) {
      getNextPageData(1, {});
    }
  }, [deviceGroupToggle, selectedContents, selectedPlaylists]);

  useEffect(() => {
    handleUpdatedContentList();
    // Convert the existing select id's into Material table readable format
    if (selectedValues?.devices?.length > 0) {
      const newObj = {};
      selectedValues?.devices?.forEach((item) => {
        newObj[item] = true;
      });
      setDeviceSelection(newObj);
    } else {
      setDeviceSelection([]);
    }
    if (selectedValues?.device_groups?.length > 0) {
      const newObj = {};
      selectedValues?.device_groups?.forEach((item) => {
        newObj[item] = true;
      });
      setDeviceGroupSelection(newObj);
    } else {
      setDeviceGroupSelection([]);
    }
  }, []);

  // Code to handle button disable
  useEffect(() => {
    if (Object.keys(deviceSelection)?.length > 0 || Object.keys(deviceGroupSelection)?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [deviceSelection, deviceGroupSelection]);

  return (
    <div className="contents-list">
      <div className="contents-list-header">
        <FormControlLabel
          control={(
            <Checkbox
              size="small"
              checked={deviceGroupToggle}
              sx={{
                color: '#30A84B',
                '&.Mui-checked': {
                  color: '#30A84B',
                },
              }}
              onChange={() => setDeviceGroupToggle(!deviceGroupToggle)}
            />
          )}
          label={t('deviceGroups')}
          sx={{
            '.muiltr-ahj2mt-MuiTypography-root': {
              fontSize: 14,
            },
          }}
        />
      </div>
      <div className="table-response">
        {!deviceGroupToggle
          ? (
            <DataTable
              header={deviceColumn}
              value={[
                ...selectedValues.devices_list,
                ...displaySignage.filter((device) => !selectedValues?.devices?.includes(device.id)),
              ]}
              status={deviceStatus}
              pageCount={devicePageCount}
              onFilterOrSortingChange={onFilterOrSortingChange}
              reduxColumnFiltersSorting={deviceTableFilter}
              totalPageCount={deviceTotalPageCount}
              isFirstRender={isFirstRender}
              totalDataCount={deviceTotalDataCount}
              scrollData={handleScroll}
              rowSelection={deviceSelection}
              columnVisibility={deviceColumnVisibility}
              setRowSelection={setDeviceSelection}
              setColumnVisibility={setDeviceColumnVisibility}
              enableRowSelection
              enable={deviceTableFilter?.columnFilters?.length > 0}
              height="45vh"
              key="device"
              disableFullScreen
            />
          ) : (
            <DataTable
              header={deviceGroupColumn}
              value={[
                ...selectedValues.device_groups_list,
                ...displayGroup.filter((device) => !selectedValues?.device_groups?.includes(device.id)),
              ]}
              status={deviceGroupStatus}
              onFilterOrSortingChange={onFilterOrSortingChange}
              reduxColumnFiltersSorting={deviceGroupTableFilter}
              pageCount={deviceGroupPageCount}
              totalPageCount={deviceGroupTotalPageCount}
              totalDataCount={deviceGroupTotalDataCount}
              isFirstRender={isFirstRender}
              scrollData={handleScroll}
              rowSelection={deviceGroupSelection}
              columnVisibility={deviceGroupColumnVisibility}
              setRowSelection={setDeviceGroupSelection}
              setColumnVisibility={setDeviceGroupColumnVisibility}
              enableRowSelection
              enable={deviceGroupTableFilter?.columnFilters?.length > 0}
              height="45vh"
              key="deviceGroup"
              disableFullScreen
            />
          )}
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('continue')} click={submit} classes="success-button" disabled={disable} />
        </div>
      </div>
    </div>
  );
}

DeviceList.propTypes = {
  selectData: PropTypes.func.isRequired,
  layout: PropTypes.objectOf().isRequired,
  contents: PropTypes.arrayOf().isRequired,
  playlists: PropTypes.arrayOf().isRequired,
  values: PropTypes.shape({
    devices: PropTypes.arrayOf().isRequired,
    devices_list: PropTypes.arrayOf().isRequired,
    device_groups: PropTypes.arrayOf().isRequired,
    device_groups_list: PropTypes.arrayOf().isRequired,
  }).isRequired,
  closeModal: PropTypes.func.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      devices_list: PropTypes.arrayOf(),
      devices: PropTypes.arrayOf(),
      orientation_flag: PropTypes.bool,
      version_flag: PropTypes.bool,
    }),
  }),
};
DeviceList.defaultProps = {
  row: null,
};
export default DeviceList;
