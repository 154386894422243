/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';
import roleApi from '../../../utils/api/roles';
import Button from '../../../shared/component/Button';
import userApi from '../../../utils/api/users';
import profile from '../../../assets/images/download.jpg';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { fileUploadValidation } from '../../../utils/helpers';

function ProfileEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = JSON.parse(localStorage.getItem('data'));
  const [value, setValue] = useState(data?.user);
  const [role, setRole] = useState('');
  const [image, setImage] = useState(data?.user?.signedUrl);
  const [uploading, setUploading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleChange = (name, changeValue) => {
    setValue({ ...value, [name]: changeValue });
  };

  const fetchRoleName = async () => {
    await roleApi.getSingleRole(data?.user?.role)
      .then((response) => {
        setRole(response?.data?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backButton = () => {
    navigate('../');
  };

  const onChangePicture = (e) => {
    const maxFileSizeInMB = 1;
    const file = e.target?.files?.[0];
    const checkFileValid = fileUploadValidation(file, maxFileSizeInMB, 'image');
    if (checkFileValid === '') {
      setValue({ ...value, image: e.target.files[0] });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      dispatch(setErrorNotification({ message: checkFileValid }));
    }
  };

  const submit = async () => {
    setUploading(true);
    const userData = {
      id: value?._id,
      name: value?.name,
      user_name: value?.user_name,
      profile_image: value?.signedUrl !== image ? value?.image : undefined,
    };
    await userApi.updateProfile(userData)
      .then((response) => {
        setUploading(false);
        setNotification(true);
        setTimeout(() => {
          localStorage.setItem('data', JSON.stringify({
            ...data,
            user: {
              ...data.user,
              name: value?.name,
              user_name: value?.user_name,
              signedUrl: response?.data.signedUrl,
            },
          }));
          setNotification(false);
          navigate('../');
        }, 3000);
      })
      .catch((err) => {
        setUploading(false);
        setErrorMsg(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (!data) {
      navigate('../');
    } else {
      fetchRoleName();
    }
  }, []);

  return (
    <div className="content">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={t('profileUpdatedSuccess')} /> }}
      />
      <div className="main-content">
        <TopContent
          label={[t('profile'), data?.user?.name]}
          button={false}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              {errorMsg && <p className="text-danger message">{errorMsg}</p>}
              <div className="user-form-wrap">
                <div className="profile-upload" style={{ height: '100%' }}>
                  <label htmlFor="file" className="upload-click">
                    <img src={image || profile} alt="profile" />
                    {!image ? <p>JPG or PNG. Max Size of 1M</p> : <p><span>{t('changeProfile')}</span></p>}
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={onChangePicture}
                    style={{ display: 'none' }}
                  />
                </div>
                <div className="user-form">
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('fullName')}
                      type="text"
                      name="name"
                      required
                      value={value?.name}
                      change={handleChange}
                      placeholder="Enter Name"
                    />
                    <Input
                      icon={' '}
                      label={t('userName')}
                      type="text"
                      name="user_name"
                      required
                      value={value?.user_name}
                      change={handleChange}
                      placeholder="Enter Username"
                    />
                  </div>
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('email')}
                      type="text"
                      name="email"
                      value={value?.email}
                      change={handleChange}
                      placeholder="Enter email"
                      readonly
                    />
                    <Input
                      icon={' '}
                      label={t('role')}
                      type="text"
                      name="role"
                      value={role}
                      change={handleChange}
                      placeholder="Enter role"
                      readonly
                    />
                  </div>
                  <div className="form-button-group">
                    <div className="form-button">
                      <Button label={t('back')} click={backButton} classes="default-button" />
                    </div>
                    <div className="form-button">
                      <Button
                        label={uploading ? t('updating') : t('update')}
                        click={submit}
                        classes={uploading ? 'success-button loading-btn' : 'success-button'}
                        loading={uploading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileEdit;
