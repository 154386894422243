import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '../../Table';

import TopContent from '../../../shared/component/TopContent';
import { Input } from '../../../shared/component/form/Input';

import { fetchSingleDisplayGroup } from '../../../redux/slices/DisplayGroupSlice';
import { checkFeatures, checkPermission } from '../../../utils/helpers';
import height from '../../../utils/size-variables';

function DisplayGroupsView() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { singleDisplayGroup } = useSelector((state) => state.displayGroup);

  const [columnVisibility, setColumnVisibility] = useState({});

  const addDisplayGroups = () => {
    navigate(`../edit/${singleDisplayGroup?.id}`);
  };

  // Column header for display signage table
  const columns = [
    {
      header: 'S.No',
      size: 60,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false,
      enableResizing: false,
      Cell: ({ row }) => row.index + 1,
    },
    {
      header: t('deviceName'),
      accessorKey: 'name',
    },
    {
      header: t('deviceOS'),
      accessorKey: 'hardware',
    },
    {
      header: t('orientation'),
      accessorKey: 'orientation',
    },
  ];

  useEffect(() => {
    dispatch(fetchSingleDisplayGroup(params?.id));
  }, [navigate]);

  return (
    <div className="content content-wrap">
      <div className="main-content">
        <TopContent
          label={[t('deviceGroups'), singleDisplayGroup?.name]}
          buttonClass="default-button button-outline-success-1"
          button={checkPermission('editDisplayGroup') && checkFeatures('display_groups', 'edit')}
          buttonLabel={t('editDisplayGroup')}
          click={addDisplayGroups}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="my-content-form">
                <Input
                  icon={' '}
                  label={t('displayGroupsName')}
                  type="text"
                  name="name"
                  required
                  readonly
                  value={singleDisplayGroup?.name}
                  change={() => { }}
                />
                <Input
                  icon={' '}
                  label={t('description')}
                  type="text"
                  name="description"
                  readonly
                  required={false}
                  value={singleDisplayGroup?.description}
                  change={() => { }}
                />
              </div>
              <Table
                header={columns}
                value={singleDisplayGroup.devices || []}
                status=""
                totalPageCount={1}
                pageCount={1}
                scrollData={() => { }}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                onFilterOrSortingChange={() => { }}
                rowSelection={{}}
                height={height.formTableHeight}
                disableFilterButton
                disableFullScreenToggle
                disableSorting
                disableFilter
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayGroupsView;
