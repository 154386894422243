import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { checkFeatures, checkPermission } from '../../utils/helpers';

function AuthGuard({ permission, Component }) {
  const permissionAssign = permission.find((per) => checkPermission(per.permission)
    && checkFeatures(per.modal, per.name));

  return permissionAssign ? Component : <Navigate to="/not-found" />; // <Navigate to="/login" />
}

AuthGuard.propTypes = {
  permission: PropTypes.arrayOf(PropTypes.string).isRequired,
  Component: PropTypes.element.isRequired,
};

export default AuthGuard;
