/* eslint no-underscore-dangle: 0 */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// import moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import ModalBox from '../../../shared/component/ModalBox';
import { Input, InputDate, InputTime } from '../../../shared/component/form/Input';
import SelectBox from '../../../shared/component/form/SelectBox';
import ContentsList from './ContentsList';
import DeviceList from './DeviceList';
import MultiSelectBox from '../../../shared/component/form/MultiSelectBox';
import PreviewContent from './PreviewContent';
import RepeatForm from './RepeatForm';
import timeZonesList from '../../../assets/json/time-zone.json';
import Button from '../../../shared/component/Button';
import scheduleApi from '../../../utils/api/schedule';
import {
  fetchScheduleList,
  setFilter,
  setTableFilter,
  fetchCalendarScheduleList,
} from '../../../redux/slices/ScheduleSlice';
import Success from '../../../shared/component/Success';
import DeleteModal from '../../../shared/component/DeleteModal';
import { CancelIcon } from '../../../shared/component/svgIcon';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import { changeDateTimeFormat, checkPermission, convertBytes } from '../../../utils/helpers';

function CalendarScheduleEdit(props) {
  const { t } = useTranslation();
  let backClick;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [disable, setDisable] = useState(true);
  const [repeatDisable, setRepeatDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalView, setModalView] = useState();
  const [selectContents, setSelectContents] = useState();
  const [selectDevices, setSelectDevices] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [notification, setNotification] = useState(false);
  const [repeatText, setRepeatText] = useState(t('doesNotRepeat'));
  const [modalTitle, setModalTitle] = useState('');
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [dateTime, setDateTime] = useState({
    start_date: null,
    end_date: null,
    start_time: null,
    end_time: null,
  });
  const [value, setValue] = useState({
    playlists: [],
    playlistDetails: [],
    contents: [],
    contentsDetails: [],
    devices: [],
    device_groups: [],
    playlists_data: [],
    contents_data: [],
    devices_data: [],
    device_groups_data: [],
    repeat_days: [],
    repeat: false,
    special: false,
  });
  // const timeZoneOptions = moment.tz.names().map((tz, index) => ({
  //   id: index,
  //   // label: `${tz} (UTC${moment.tz(tz).format('Z')})`,
  //   label: tz,
  //   key: tz,
  // }));
  const devicesData = useSelector((state) => state.display.displaySignage);
  const devicesGroupData = useSelector((state) => state.displayGroup.displayGroup);
  const week = useSelector((state) => state.schedules.week);
  const setRepeated = (repValues) => {
    setValue({ ...value, ...repValues, repeat: true });
    setMessage(
      repValues.repeat_type === 'DAILY'
        ? `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
        day starting ${repValues.started_date} until ${repValues.complete_date}`
        : `Occurs every ${repValues.repeat_count > 1 ? repValues.repeat_count : ''} 
        week ${repValues?.repeat_days} starting ${repValues.started_date} until ${repValues.complete_date}`,
    );

    setOpen(false);
  };
  const backModal = (name, data) => {
    backClick(name, data);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const submit = () => {
    if (
      `${value.start_date} ${value.start_time}` < `${value.end_date} ${value.end_time}`
    ) {
      setDisable(true);
      setUploading(true);
      const request = {

        name: value.name,
        repeat: value.repeat,
        start_only_date: value.start_date,
        end_only_date: value.end_date,
        start_time: value.start_time,
        end_time: value.end_time,
        time_zone: value.time_zone,
        special: value.special,
      };

      if (value.description !== '') {
        request.description = value.description;
      }

      if (value.contents.length > 0) {
        request.contents = value.contents;
      }

      if (value.devices.length > 0) {
        request.devices = value.devices;
      }

      if (value.device_groups.length > 0) {
        request.device_groups = value.device_groups;
      }

      if (value.playlists.length > 0) {
        request.playlists = value.playlists;
      }

      if (value.repeat) {
        request.recurrences = {
          interval: value.repeat_count,
          frequency: value.repeat_type,
          start_only_date: value.started_date,
          end_only_date: value.complete_date,
        };

        if (value.repeat_type === 'WEEKLY') {
          request.recurrences.by_week_day = value.repeat_days;
        }
      }
      scheduleApi.updateSchedule(request, props?.event.schedule_id).then(() => {
        setUploading(false);
        setNotification(true);
        setModalView(<Success message={t('scheduleUpdatedSuccess')} />);
        setModalTitle('');
        setOpen(true);
        const sortedArray = week.slice().sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        dispatch(fetchCalendarScheduleList({
          start_date: sortedArray[0].start_date,
          end_date: sortedArray[sortedArray.length - 1].end_date,
          status: 'add',
        }));
        setTimeout(() => {
          setNotification(false);
          setOpen(false);
          setDisable(false);
          props?.back(false);
          navigate('../calendar-schedule');
          dispatch(fetchScheduleList({ page: 1 }));
          dispatch(setFilter({}));
          dispatch(setTableFilter({}));
        }, 3000);
      }).catch((errors) => {
        setUploading(false);
        setError(errors?.response?.data?.message);
        setDisable(false);
        dispatch(setErrorNotification(errors?.response?.data));
      });
    } else {
      dispatch(setErrorNotification({ message: 'Invalid schedule time' }));
    }
  };
  const getId = () => {
    scheduleApi.cancelSchedule(props?.event?.schedule_id).then(() => {
      setOpenDeleteModal(false);
      setOpen(false);
      const sortedArray = week.slice().sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
      dispatch(fetchCalendarScheduleList({
        start_date: sortedArray[0].start_date,
        end_date: sortedArray[sortedArray.length - 1].end_date,
        status: 'add',
      }));
      props?.back(false);
      navigate('../calendar-schedule');
    }).catch((err) => {
      dispatch(setErrorNotification(err?.response?.data));
    });
  };
  const openModalRepeat = () => {
    setModalView(<RepeatForm
      closeModal={setOpen}
      setRepeated={setRepeated}
      startDate={value}
    />);
    setModalTitle('Repeat');
    setOpen(true);
  };
  const selectData = (name, data) => {
    if (name === 'time_zone') {
      if (data) {
        setValue((prevState) => ({
          ...prevState,
          time_zone: data.label,
        }));
      }
    } else if (name === 'devices' || name === 'contents') {
      setValue((prevState) => ({
        ...prevState,
        ...data,
      }));
    } else if (name === 'start_date' || name === 'end_date') {
      setDateTime((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      setValue((prevState) => ({
        ...prevState,
        [name]: dayjs(data).format('YYYY-MM-DD'),
      }));
    } else if (name === 'start_time' || name === 'end_time') {
      setDateTime((prevState) => ({
        ...prevState,
        [name]: data,
      }));

      // Formatting time
      const hour = data?.$H;
      const minute = data?.$m;
      const formattedTime = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
      setValue((prevState) => ({
        ...prevState,
        [name]: formattedTime,
      }));
    } else {
      setValue((prevState) => ({
        ...prevState,
        [name]: data,
      }));
    }
  };
  const viewData = (name, data) => {
    setModalView(<PreviewContent
      data={data}
      closeModal={setOpen}
      name={name}
      selectData={selectData}
      values={value}
      back={backModal}
    />);
  };
  const cancel = () => {
    setOpenDeleteModal(true);
  };
  const removeContentsTags = (name, key) => {
    const contentsList = value[key.type].filter((e) => e !== key.id);
    let detail = '';
    let oldValue = [];
    if (key.type === 'contents') {
      detail = 'contentsDetails';
      oldValue = value.contentsDetails.filter((content) => content.id !== key.id);
    } else if (key.type === 'playlists') {
      detail = 'playlistDetails';
      oldValue = value.playlistDetails.filter((content) => content.id !== key.id);
    }
    setValue({ ...value, [key.type]: contentsList, [detail]: oldValue });
  };
  const openModalContent = (name = 'contents', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView(<ContentsList selectData={viewData} closeModal={setOpen} values={oldData} name={name} />);
    setModalTitle('Contents');
    setOpen(true);
  };
  const openModalDevice = (name = 'devices', data = {}) => {
    const oldData = Object.keys(data).length !== 0 ? data : value;
    setModalView(<DeviceList selectData={viewData} closeModal={setOpen} values={oldData} name={name} />);
    setModalTitle('Devices');
    setOpen(true);
  };
  backClick = (name, data) => {
    if (name === 'contents' || name === 'playlists') {
      openModalContent(name, data);
    } else if (name === 'devices' || name === 'device_groups') {
      openModalDevice(name, data);
    }
  };
  useEffect(() => {
    if (value.start_date && value.start_time && value.end_date && value.end_time && value.name && value.time_zone) {
      if (value.contents.length > 0 && value.devices.length > 0) {
        setDisable(false);
      } else if (value.contents.length > 0 && value.device_groups.length > 0) {
        setDisable(false);
      } else if (value.playlists.length > 0 && value.devices.length > 0) {
        setDisable(false);
      } else if (value.playlists.length > 0 && value.device_groups.length > 0) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
    if (value.start_date && value.start_time && value.end_date && value.end_time) {
      const now = `${value.start_date} ${value.start_time}`;
      const then = `${value.end_date} ${value.end_time}`;
      const startTime = new Date(now).getTime();
      const endTime = new Date(then).getTime();
      const timeDifference = Math.abs(endTime - startTime);
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      // const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      if (hours <= 23) {
        setRepeatDisable(false);
        setRepeatText(t('repeat'));
      } else {
        setValue({ ...value, repeat: false });
        setRepeatDisable(true);
        setRepeatText(t('doesNotRepeat'));
      }
    }
    let singleDevice = [];
    let singleDevicesGroup = [];
    if (devicesData.length > 0) {
      const device = devicesData.filter((e) => value.devices.includes(e.id));
      singleDevice = device.map((value1) => ({ ...value1, type: 'devices' }));
    } else {
      singleDevice = value?.devices_data.length > 0 ? value?.devices_data : [];
    }
    if (devicesGroupData.length > 0) {
      const deviceGroup = devicesGroupData.filter((e) => value.device_groups.includes(e.id));
      singleDevicesGroup = deviceGroup.map((value1) => ({ ...value1, type: 'device_groups' }));
    } else {
      singleDevicesGroup = value?.device_groups_data.length > 0 ? value?.device_groups_data : [];
    }
    setSelectContents(
      [
        ...value.contentsDetails.map((value1) => ({ ...value1, type: 'contents' })),
        ...value.playlistDetails.map((value1) => ({ ...value1, type: 'playlists' })),
      ],
    );
    setSelectDevices([...singleDevice, ...singleDevicesGroup]);
  }, [value]);
  useEffect(() => {
    scheduleApi.getSingleSchedule(props?.event.schedule_id).then((response) => {
      setValue({
        ...response.data,
        contentsDetails: response.data.contents.map((value1) => ({
          ...value1,
          type: 'contents',
          id: value1._id,
          author: value1.createdBy?.name,
          createDate: changeDateTimeFormat(value1.createdAt),
          updateDate: changeDateTimeFormat(value1.updatedAt),
          file_size: convertBytes(value1.file_size),
        })),
        contents: response.data.contents.map((val) => val._id),
        playlistDetails: response.data.playlists.map((value1) => ({
          ...value1,
          type: 'playlists',
          id: value1._id,
          author: value1.createdBy?.name,
          createDate: changeDateTimeFormat(value1.createdAt),
          updateDate: changeDateTimeFormat(value1.updatedAt),
          totalFileSize: convertBytes(value1.totalFileSize),
        })),
        playlists: response.data.playlists.map((val) => val._id),
        devices_data: response.data.devices.map((value1) => ({
          ...value1,
          type: 'devices',
          id: value1._id,
        })),
        devices: response.data.devices.map((val) => val._id),
        devices_list: response.data.devices || [],
        device_groups_data: response.data.device_groups.map((value1) => ({
          ...value1,
          type: 'device_groups',
          id: value1._id,
        })),
        id: response?.data?._id,
        device_groups: response.data.device_groups.map((val) => val._id),
        device_groups_list: response.data.device_groups || [],
        special: response.data?.special,
        end_date: props?.selectedEndDate || response.data.end_only_date,
        start_date: props?.selectedDate || response.data.start_only_date,
        start_time: props?.selectedTime || response.data.start_time,
        end_time: props?.selectedTimeend || response.data.end_time,
        repeat_count: response.data.recurrences?.interval,
        repeat_type: response.data.recurrences?.frequency,
        started_date: response.data.recurrences?.start_only_date,
        complete_date: response.data.recurrences?.end_only_date,
        repeat_days: response.data?.recurrences && response.data?.recurrences?.frequency !== 'DAILY'
          ? response.data?.recurrences?.by_week_day
          : [],
      });
      if (props?.selectedDate && props?.selectedEndDate) {
        setDateTime({
          start_date: dayjs(props?.selectedDate),
          end_date: dayjs(props?.selectedEndDate),
          start_time: dayjs(`2024-12-04 ${props?.selectedTime}`),
          end_time: dayjs(`2024-12-04 ${props?.selectedTimeend}`),
        });
      } else {
        setDateTime({
          start_date: dayjs(response.data.start_only_date),
          end_date: dayjs(response.data.end_only_date),
          start_time: dayjs(`2024-12-04 ${response.data.start_time}`),
          end_time: dayjs(`2024-12-04 ${response.data.end_time}`),
        });
      }
    });
  }, []);
  // useEffect(() => {
  //   const selectedOption = timeZoneOptions.find((option) => option.keys === option.key);
  //   if (selectedOption) {
  //     if (selectedOption.keys === selectedOption.key) {
  //       setValue({ ...value, time_zone: selectedOption.key, time_zone_label: selectedOption.label });
  //     } else {
  //       setValue({ ...value, time_zone: timeSteps });
  //     }
  //   }
  // }, []);
  useEffect(() => {
    const timeZones = timeZonesList.map((val) => ({ id: val, label: val }));
    setTimeZone(timeZones);
  }, [navigate]);
  return (
    <div className="calender-schedule-form">
      {error && <p className="text-danger message">{error}</p>}
      <ModalBox
        status={open}
        modalView={{ content: modalView, title: modalTitle }}
        closeModal={closeModal}
        notification={notification}
      />
      <DeleteModal
        closeModal={setOpenDeleteModal}
        status={openDeleteModal}
        okFunction={getId}
        buttonLabel={t('confirm')}
        title={t('areYouCancelSchedule')}
        subTitle={t('deleteScheduleSubMessage')}
      />
      <div className="form-field-row">
        <Input
          icon={' '}
          label={t('scheduleName')}
          type="text"
          name="name"
          placeholder={t('enterScheduleName')}
          required
          value={value.name}
          change={selectData}
        />
        <SelectBox
          label={t('timeZone')}
          name="time_zone"
          required
          value={value.time_zone}
          onchange={selectData}
          lists={timeZone}
        />
      </div>
      <div className="form-select-field">
        <Input
          label={t('description')}
          type="text"
          name="description"
          placeholder={t('enterScheduleDescription')}
          required={false}
          value={value.description}
          change={selectData}
        />
      </div>
      <div className="form-field-row">
        <div className="form-field-1">
          <InputDate
            label={t('scheduleFrom')}
            name="start_date"
            required
            value={dateTime.start_date}
            change={selectData}
            disablePast
          />
          <InputTime
            label={t('startTime')}
            name="start_time"
            required
            value={dateTime.start_time}
            change={selectData}
            minTime={
              value.start_date === dayjs(new Date()).format('YYYY-MM-DD') ? dayjs(new Date()) : undefined
            }
          />
        </div>
        <div className="form-field-1">
          <InputDate
            label={t('scheduleTill')}
            name="end_date"
            required
            value={dateTime.end_date}
            change={selectData}
            disablePast
          />
          <InputTime
            label={t('endTime')}
            name="end_time"
            required
            value={dateTime.end_time}
            change={selectData}
          />
        </div>
      </div>
      {
        checkPermission('repeatSchedule') && (
          <div className="form-field-button">
            <Button
              label={repeatText}
              classes="info-button"
              click={openModalRepeat}
              disabled={repeatDisable}
            />
          </div>
        )
      }
      {message && <div className="form-select-field info-text">{message}</div>}
      <div className="form-select-field">
        <MultiSelectBox
          label={[`${t('targetDevice')} / `, `${t('deviceGroups')}`]}
          buttonLabel={t('selectDevice')}
          clickButton={openModalDevice}
          required
          name="devices"
          addData={removeContentsTags}
          placeholder={t('selectTargetDevice')}
          value={selectDevices}
        />
      </div>
      <div className="form-select-field">
        <MultiSelectBox
          label={[`${t('content')} / `, `${t('playlist')}`]}
          buttonLabel={t('selectContent')}
          clickButton={openModalContent}
          required
          name="contents"
          addData={removeContentsTags}
          placeholder={t('selectTargetContent')}
          value={selectContents}
        />
      </div>
      <div className="edit-form-button-group">
        <div role="presentation" onClick={cancel} className="cancel-group">
          <CancelIcon />
          <Button
            label={t('cancel')}
            classes="cancel-button"
          />
        </div>
        <div className="edit-form-button-group-right">
          <div className="form-button">
            <Button
              label={t('back')}
              click={() => props?.back(false)}
              classes="default-button"
            />
          </div>
          <div className="form-button">
            <Button
              label={uploading ? t('updating') : t('update')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>

  );
}
CalendarScheduleEdit.propTypes = {
  event: PropTypes.objectOf(),
  selectedEndDate: PropTypes.string,
  selectedDate: PropTypes.string,
  selectedTime: PropTypes.string,
  selectedTimeend: PropTypes.string,
  back: PropTypes.func,
};
CalendarScheduleEdit.defaultProps = {
  event: {},
  selectedEndDate: '',
  selectedDate: '',
  selectedTime: '',
  selectedTimeend: '',
  back: () => { },
};
export default CalendarScheduleEdit;
