import { configureStore } from '@reduxjs/toolkit';

import myContentReducer from './slices/MyContentSlice';
import playlistReducer from './slices/PlaylistSlice';
import playlistContentReducer from './slices/PlaylistContentSlice';

import storeReducer from './slices/StoreLocationSlice';
import widgetSlice from './slices/WidgetTempSlice';
import displaySignageReducer from './slices/DisplaySignageSlice';
import displayGroupReducer from './slices/DisplayGroupSlice';
import dGDisplaySignageReducer from './slices/DGDisplaySignageSlice';

import schedulesReducer from './slices/ScheduleSlice';
import scheduleMyContentReducer from './slices/ScheduleMyContentSlice';
import schedulePlaylistReducer from './slices/SchedulePlaylistSlice';
import scheduleDisplaySignageReducer from './slices/ScheduleDisplaySignageSlice';
import scheduleDisplayGroupReducer from './slices/ScheduleDisplayGroupSlice';
import scheduleLayoutMyContentReducer from './slices/ScheduleLayoutMyContentSlice';
import scheduleLayoutPlaylistReducer from './slices/ScheduleLayoutPlaylistSlice';

import rolesReducer from './slices/RolesSlice';
import userInputSlice from './slices/WidgetSlice';
import usersReducer from './slices/UsersSlice';

import snapshotReducer from './slices/SnapshotSlice';
import snapshotStoreReducer from './slices/SnapshotStoreLocationSlice';
import snapshotDisplaySignageReducer from './slices/SnapshotDisplaySignageSlice';
import snapshotDisplayGroupReducer from './slices/SnapshotDisplayGroupSlice';
import snapshotSchedulesReducer from './slices/SnapshotScheduleSlice';

import subscriptionReducer from './slices/SubscriptionSlice';
import otaUpdateReducer from './slices/OtaUpdateSlice';
import preferenceReducer from './slices/AccountPreferenceSlice';
import dashboardReducer from './slices/DashBoardSlice';
import notificationReducer from './slices/NotificationSlice';
import deviceActiveLogsReducer from './slices/DeviceActiveLogsSlice';
import popScheduleReducer from './slices/POPScheduleSlice';
import rentalHistoryReducer from './slices/RentalHistorySlice';
import contentLayoutReducer from './slices/ContentLayoutSlice';
import savedContentLayoutReducer from './slices/SavedContentLayoutSlice';
import contentLayoutWidgetsReducer from './slices/ContentLayoutWidgetsSlice';

import templateReducer from './slices/TemplateSlice';
import PosTemplateReducer from './slices/PosTemplateSlice';
// import allTemplatesReducer from './slices/allTemplateSlice';
import storeNewReducer from './slices/StoresSlice';

const store = configureStore({
  reducer: {
    // Content_Management
    myContent: myContentReducer,
    playlist: playlistReducer,
    playlistContent: playlistContentReducer,
    // Store_Management
    store: storeReducer,
    // Display_Management
    display: displaySignageReducer,
    displayGroup: displayGroupReducer,
    dGDisplay: dGDisplaySignageReducer,
    // Schedule_Management
    schedules: schedulesReducer,
    scheduleMyContent: scheduleMyContentReducer,
    schedulePlaylist: schedulePlaylistReducer,
    scheduleDisplaySignage: scheduleDisplaySignageReducer,
    scheduleDisplayGroup: scheduleDisplayGroupReducer,
    scheduleLayoutMyContent: scheduleLayoutMyContentReducer,
    scheduleLayoutPlaylist: scheduleLayoutPlaylistReducer,
    // User_Management
    roles: rolesReducer,
    widget: userInputSlice,
    widgets: widgetSlice,
    users: usersReducer,
    // Snapshot
    snapshot: snapshotReducer,
    snapshotStore: snapshotStoreReducer,
    snapshotDisplay: snapshotDisplaySignageReducer,
    snapshotDisplayGroup: snapshotDisplayGroupReducer,
    snapshotSchedules: snapshotSchedulesReducer,
    // Rental History
    rentalHistory: rentalHistoryReducer,
    // Other_reducers
    subscription: subscriptionReducer,
    otaUpdate: otaUpdateReducer,
    accountPreference: preferenceReducer,
    notification: notificationReducer,
    deviceActiveLogs: deviceActiveLogsReducer,
    dashboardPieCharts: dashboardReducer,
    popSchedule: popScheduleReducer,
    currentTemplate: templateReducer,
    posTemplate: PosTemplateReducer,
    // allTemplates: allTemplatesReducer,
    // Content Layout
    contentLayout: contentLayoutReducer,
    savedContentLayout: savedContentLayoutReducer,
    contentLayoutWidgets: contentLayoutWidgetsReducer,
    facilities: storeNewReducer,
  },
});

export default store;
