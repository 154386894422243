import React, { useState, useEffect, forwardRef } from 'react';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { resetNotification } from '../../redux/slices/NotificationSlice';
import ModalBox from './ModalBox';
import ErrorAlert from './ErrorAlert';
import Authenticate from './Authenticate';
import SubscriptionRenewal from './SubscriptionRenewal';

const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

function Notification() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const { errorNotification } = useSelector((state) => state.notification);

  const [errorMessage, setErrorMessage] = useState('');
  const [successNotification, setSuccessNotification] = useState(false);
  const [subscriptionNotification, setSubscriptionNotification] = useState(false);
  const [modalView, setModalView] = useState();

  const handleClose = () => {
    dispatch(resetNotification());
    setSuccessNotification(false);
    setOpen(false);
  };

  useEffect(() => {
    if (errorNotification && Object.keys(errorNotification).length > 0) {
      if (errorNotification?.code === 401) {
        if (location.pathname.startsWith('/admin')) {
          setModalView({ content: <Authenticate setSuccessNotification={setSuccessNotification} /> });
          setSuccessNotification(true);
        }
      } else {
        if (errorNotification?.message === 'Subscription expired') {
          setSubscriptionNotification(true);
        } else if (errorNotification?.message === 'Subscription cancelled') {
          setSubscriptionNotification(true);
        } else if (errorNotification?.message === 'Company cancel') {
          setModalView({ content: <ErrorAlert message={t('companyDeletedMessage')} /> });
          setSuccessNotification(true);
        } else if (errorNotification?.message) {
          setOpen(true);
          setErrorMessage(errorNotification?.message);
        }
        setTimeout(() => {
          setSuccessNotification(false);
        }, 5000);
      }
    }
  }, [errorNotification]);

  return (
    <>
      <ModalBox
        status={successNotification}
        closeModal={handleClose}
        modalView={modalView}
      />
      <ModalBox
        status={subscriptionNotification}
        closeModal={setSubscriptionNotification}
        modalView={{
          content: <SubscriptionRenewal
            errorMessage={errorNotification?.message}
            close={setSubscriptionNotification}
          />,
        }}
        notification={errorNotification?.code !== 403}
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Notification;
