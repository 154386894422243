import {
  get,
  post,
  patch,
  deletes,
} from './base/index';

export default {
  getDevicesGroups: (data = {}) => {
    const params = { ...data, limit: data.limit || 20 };
    return get(`${process.env.REACT_APP_API_URL}cms/v1/deviceGroups`, { params });
  },
  getSingleDevicesGroup: (data = 0) => get(`${process.env.REACT_APP_API_URL}cms/v1/deviceGroups/${data}`, {}),
  addDevicesGroup: (data = {}) => {
    const body = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/deviceGroups`, body, false);
  },
  updateDevicesGroup: (data = {}) => {
    const params = {
      devices: data.devices,
      name: data.name,
      description: Object.keys(data)?.includes('description') ? data.description : undefined,
    };
    return patch(`${process.env.REACT_APP_API_URL}cms/v1/deviceGroups/${data.id}`, params);
  },
  deleteDevicesGroup: (data = 0) => deletes(`${process.env.REACT_APP_API_URL}cms/v1/deviceGroups/${data}`, {}),
  deviceGroupExport: (data = {}) => post(`${process.env.REACT_APP_API_URL}cms/v1/deviceGroups/export`, { ...data }),
};
