import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputIncreaseDecrease } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import { currencySymbol } from '../../../utils/helpers';
import subscription from '../../../utils/api/subscription';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function DisplayIncreaseModal(props) {
  const dispatch = useDispatch();
  const {
    submitDeviceCount,
    closeModal,
    deviceAdd,
    totalDevice,
    currency,
  } = props;

  const { t } = useTranslation();
  const [deviceCount, setDeviceCount] = useState(deviceAdd);
  const [disable, setDisable] = useState(true);
  const [loadingPrice, setLoadingPrice] = useState(false);

  const handleInputChange = (name, changeValue) => {
    if (!loadingPrice) {
      setLoadingPrice(true);
      const req = {
        deviceCountChange: changeValue - totalDevice,
      };
      subscription.calculatePrice(req)
        .then((res) => {
          setLoadingPrice(false);
          setDeviceCount((prevValue) => ({
            ...prevValue,
            [name]: changeValue - totalDevice,
            totalDevice: changeValue,
            price: res?.data?.additionalDevicePrice,
            unitPrice: res?.data?.perDevicePrice,
          }));
        })
        .catch((error) => {
          setLoadingPrice(false);
          dispatch(setErrorNotification(error?.response?.data));
        });
    }
  };

  const back = () => closeModal(false);

  const submitDevice = () => {
    if (deviceCount.totalDevice > 0) {
      submitDeviceCount(deviceCount);
    } else {
      setDisable(true);
    }
  };

  useEffect(() => {
    if (deviceCount.totalDevice > 0 && totalDevice !== deviceCount.totalDevice) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [deviceCount]);

  return (
    <div className="display-information">
      <div className="display-form">
        <div className="field">
          <InputIncreaseDecrease
            icon={' '}
            label={t('device')}
            type="number"
            name="device"
            required={false}
            value={deviceCount.totalDevice}
            change={handleInputChange}
            placeholder="0"
            min={0}
          />
        </div>
        <div className="field-price">
          {deviceCount.device} * {currencySymbol(currency, deviceCount.unitPrice)}/{t('device')} =
          {currencySymbol(currency, deviceCount.price)}
        </div>
      </div>
      <div className="form-button-group pr-10 pb-10">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('confirm')} click={submitDevice} classes="success-button" disabled={disable} />
        </div>
      </div>
    </div>
  );
}

DisplayIncreaseModal.propTypes = {
  submitDeviceCount: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  totalDevice: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  deviceAdd: PropTypes.shape({
    device: PropTypes.number,
    totalDevice: PropTypes.number,
    price: PropTypes.number,
  }),
};
DisplayIncreaseModal.defaultProps = {
  deviceAdd: {},
};

export default DisplayIncreaseModal;
