import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TopContent from '../../shared/component/TopContent';
import { Input } from '../../shared/component/form/Input';

import roleApi from '../../utils/api/roles';

import profile from '../../assets/images/download.jpg';
import { checkPermission } from '../../utils/helpers';

function Profile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = JSON.parse(localStorage.getItem('data'));
  const [role, setRole] = useState('');

  const addPlaylist = () => {
    navigate('edit');
  };

  const fetchRoleName = async () => {
    await roleApi.getSingleRole(user?.role)
      .then((response) => {
        setRole(response?.data?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      fetchRoleName();
    }
  }, [user]);

  return (
    <div className="content">
      <div className="main-content">
        <TopContent
          label={t('myProfile')}
          buttonClass="default-button button-outline-success-1"
          button={checkPermission('updateProfile')}
          buttonLabel={t('editProfile')}
          click={addPlaylist}
        />
        <div className="content-area">
          <div className="my-content">
            <div className="my-content-area-form">
              <div className="user-form-wrap">
                <div className="profile-upload" style={{ height: '100%' }}>
                  <label htmlFor="file" className="upload-click">
                    <img src={user?.signedUrl || profile} alt="profile" />
                  </label>
                </div>
                <div className="user-form" style={{ padding: '30px 25px' }}>
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('name')}
                      type="text"
                      name="company_name"
                      readonly
                      value={user.name}
                    />
                    <Input
                      icon={' '}
                      label={t('userName')}
                      type="text"
                      name="username"
                      readonly
                      value={user.user_name}
                    />
                  </div>
                  <div className="single-row">
                    <Input
                      icon={' '}
                      label={t('mailId')}
                      type="text"
                      name="mail_id"
                      readonly
                      value={user.email}
                    />
                    <Input
                      icon={' '}
                      label={t('userRole')}
                      type="text"
                      name="role"
                      readonly
                      value={role}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
