import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
// import { InputInnerIcon } from '../../../shared/component/form/Input';

// import Search from '../../../assets/icons/search.png';
import loginImage from '../../../assets/images/loginPage.jpg';
import youtubeIcon from '../../../assets/images/youtube-icon.png';
import urlIcon from '../../../assets/images/url-image.png';
import pdfIcon from '../../../assets/images/pdf-icon.png';

import Button from '../../../shared/component/Button';
import PopupModal from '../../../shared/component/PopupModel';
import ModalBox from '../../../shared/component/ModalBox';
import TableHeaderSelectCount from '../../../shared/component/TableHeaderSelectCount';

import {
  fetchScheduleContentList,
  setFilter as contentSetFilter,
  setMuiTableData as contentSetMuiTableDate,
} from '../../../redux/slices/ScheduleMyContentSlice';
import {
  fetchSchedulePlaylistList,
  setFilter as playlistSetFilter,
  setMuiTableData as playlistSetMuiTableDate,
} from '../../../redux/slices/SchedulePlaylistSlice';
import DataTable from '../../Table';
import ContentView from '../../MyContent/components/ContentView';
import { addImageType } from '../../../utils/helpers';

function ContentsList(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    contents,
    pageCount: contentPageCount,
    filter: contentFilter,
    muiTableData: contentMuiData,
    totalPageCount: contentTotalPageCount,
    totalDataCount: contentDataCount,
    status: contentStatus,
  } = useSelector((state) => state.scheduleMyContent);

  const {
    playlist,
    pageCount: playlistPageCount,
    filter: playlistFilter,
    muiTableData: playlistMuiData,
    totalPageCount: playlistTotalPageCount,
    totalDataCount: playlistDataCount,
    status: playlistStatus,
  } = useSelector((state) => state.schedulePlaylist);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [checked, setChecked] = useState(props?.name !== 'contents');
  const [disable, setDisable] = useState(true);
  const [allValues, setAllValues] = useState({
    contents: props?.values?.contents || [],
    playlists: props?.values?.playlists || [],
    contentsDetails: props?.values?.contentsDetails || [],
    playlistDetails: props?.values?.playlistDetails || [],
  });

  const [selectContent, setSelectContent] = useState(false);
  const [selectPlaylist, setSelectPlaylist] = useState(false);
  const [contentSelection, setContentSelection] = useState({});
  const [playlistSelection, setPlaylistSelection] = useState({});
  const [contentColumnVisibility, setContentColumnVisibility] = useState({});
  const [playlistColumnVisibility, setPlaylistColumnVisibility] = useState({});

  const [contentViewPopup, setContentViewPopup] = useState(false);
  const [modalView, setModalView] = useState({});

  const viewContent = (singleContent) => {
    setContentViewPopup(true);
    setModalView({
      title: t('preview'),
      content: <ContentView singleContent={singleContent} />,
    });
  };

  const contentColumns = [
    {
      header: t('contentName'),
      accessorKey: 'name',
      enableSorting: false,
      filterFn: 'commonFIlterFn',
      Cell: ({ row }) => (
        <div className="content-thumbnail">
          {
            (() => {
              let thumbnailImage;
              if (row.original.file_type === 'youtube') {
                thumbnailImage = (
                  <img
                    alt="Youtube"
                    src={youtubeIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original.file_type === 'url') {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={urlIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else if (row.original.file_type.includes('pdf')) {
                thumbnailImage = (
                  <img
                    alt="Url"
                    src={pdfIcon}
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                    className="default-image"
                  />
                );
              } else {
                thumbnailImage = (
                  <img
                    alt="thumbnail"
                    src={
                      row.original.thumbnailSignedUrls?.px_50x50
                      || loginImage
                    }
                    loading="lazy"
                    role="presentation"
                    onClick={() => viewContent(row.original)}
                  />
                );
              }
              return thumbnailImage;
            })()
          }
          <span className="content_name">
            {row.original.name}
          </span>
        </div>
      ),
    },
    {
      header: t('mediaType'),
      accessorKey: 'file_type',
      enableSorting: false,
      Cell: ({ row }) => addImageType(row?.original?.file_type),
    },
    {
      header: t('author'),
      enableSorting: false,
      accessorKey: 'author',
      Cell: ({ row }) => row.original.author,
    },
    {
      header: t('createdDate'),
      accessorKey: 'createDate',
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.createDate,
    },
    {
      header: t('fileSize'),
      accessorKey: 'file_size',
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];

  const playlistColumns = [
    {
      header: t('playlistName'),
      accessorKey: 'name',
      enableSorting: false,
    },
    {
      header: t('totalContents'),
      accessorKey: 'count',
      muiFilterTextFieldProps: { type: 'number', min: 1 },
      filterFn: 'commonFIlterFn',
      enableSorting: false,
      Cell: ({ row }) => <PopupModal list={row.original.contents_list} name="contents list" />,
    },
    {
      header: t('author'),
      accessorKey: 'user',
      enableSorting: false,
      Cell: ({ row }) => row.original.author,
    },
    {
      header: t('createdDate'),
      accessorKey: 'createDate',
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ row }) => row.original.createDate,
    },
    {
      header: t('fileSize'),
      accessorKey: 'totalFileSize',
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];

  const getContents = (count, filter) => {
    if (checked && playlistStatus === 'succeeded') {
      dispatch(fetchSchedulePlaylistList({ page: count, limit: 20, ...filter }));
    } else if (contentStatus === 'succeeded') {
      dispatch(fetchScheduleContentList({ page: count, limit: 20, ...filter }));
    }
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleScroll = () => {
    if (checked) {
      getContents(playlistPageCount + 1, playlistFilter);
    } else {
      getContents(contentPageCount + 1, contentFilter);
    }
  };

  const back = () => {
    props?.closeModal(false);
  };

  const submit = () => {
    props?.selectData('contents', allValues);
  };

  const onFilterOrSortingChange = (filteredValues, reduxColumnFiltersSorting) => {
    if (checked) {
      dispatch(playlistSetMuiTableDate(reduxColumnFiltersSorting));
      dispatch(playlistSetFilter(filteredValues));
      getContents(1, filteredValues);
    } else {
      dispatch(contentSetMuiTableDate(reduxColumnFiltersSorting));
      dispatch(contentSetFilter(filteredValues));
      getContents(1, filteredValues);
    }
  };

  useEffect(() => {
    if (allValues.contents.length > 0 || allValues.playlists.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [allValues]);

  useEffect(() => {
    setIsFirstRender(false);
    getContents(1, checked ? playlistFilter : contentFilter);
    if (allValues?.contents?.length > 0) {
      const newObj = {};
      allValues?.contents?.forEach((item) => {
        newObj[item] = true;
      });
      setSelectContent(false);
      setContentSelection(newObj);
    }
    if (allValues?.playlists?.length > 0) {
      const newObj = {};
      allValues?.playlists?.forEach((item) => {
        newObj[item] = true;
      });
      setSelectPlaylist(false);
      setPlaylistSelection(newObj);
    }
  }, [checked]);

  useEffect(() => {
    if (selectContent) {
      let allContents = [...allValues.contentsDetails, ...contents];
      allContents = allContents.filter((content) => Object.keys(contentSelection).includes(content.id));
      setAllValues({
        ...allValues,
        contents: Object.keys(contentSelection) || [],
        contentsDetails: allContents.filter(
          (ele, ind) => ind === allContents.findIndex((elem) => elem.id === ele.id),
        ),
      });
    } else {
      setSelectContent(true);
    }
  }, [contentSelection]);

  useEffect(() => {
    if (selectPlaylist) {
      let allPlaylist = [...allValues.playlistDetails, ...playlist];
      allPlaylist = allPlaylist.filter((playlist1) => Object.keys(playlistSelection).includes(playlist1.id));
      setAllValues({
        ...allValues,
        playlists: Object.keys(playlistSelection) || [],
        playlistDetails: allPlaylist.filter(
          (ele, ind) => ind === allPlaylist.findIndex((elem) => elem.id === ele.id),
        ),
      });
    } else {
      setSelectPlaylist(true);
    }
  }, [playlistSelection]);
  return (
    <div className="contents-list">
      <ModalBox
        status={contentViewPopup}
        closeModal={setContentViewPopup}
        modalView={modalView}
      />
      <div className="contents-list-header">
        <FormControlLabel
          control={(
            <Checkbox
              checked={checked}
              size="small"
              sx={{
                color: '#30A84B',
                '&.Mui-checked': {
                  color: '#30A84B',
                },
              }}
              onChange={handleChange}
            />
          )}
          label={t('playlist')}
          sx={{
            '.MuiTypography-root': {
              fontSize: 14,
            },
          }}
        />
      </div>
      <div className="table-response">
        {
          checked ? (
            <DataTable
              header={playlistColumns}
              value={[
                ...allValues.playlistDetails,
                ...playlist.filter((playlists) => !allValues?.playlists?.includes(playlists.id)),
              ]}
              status={playlistStatus}
              onFilterOrSortingChange={onFilterOrSortingChange}
              reduxColumnFiltersSorting={playlistMuiData}
              isFirstRender={isFirstRender}
              totalPageCount={playlistTotalPageCount}
              pageCount={playlistPageCount}
              scrollData={handleScroll}
              totalDataCount={playlistDataCount}
              rowSelection={playlistSelection}
              setRowSelection={setPlaylistSelection}
              columnVisibility={playlistColumnVisibility}
              setColumnVisibility={setPlaylistColumnVisibility}
              enable={playlistMuiData?.columnFilters?.length > 0}
              height="45vh"
              // disableFilter
              disableSorting
              disableFullScreenToggle
              enableRowSelection
              disableFullScreen
              toolbarLeft={(
                <TableHeaderSelectCount
                  select={Object.keys(playlistSelection).length}
                  total={playlistDataCount}
                />
              )}
            />
          ) : (
            <DataTable
              header={contentColumns}
              value={[
                ...allValues.contentsDetails,
                ...contents.filter((content) => !allValues?.contents?.includes(content.id)),
              ]}
              status={contentStatus}
              onFilterOrSortingChange={onFilterOrSortingChange}
              reduxColumnFiltersSorting={contentMuiData}
              isFirstRender={isFirstRender}
              totalPageCount={contentTotalPageCount}
              pageCount={contentPageCount}
              scrollData={handleScroll}
              totalDataCount={contentDataCount}
              rowSelection={contentSelection}
              setRowSelection={setContentSelection}
              columnVisibility={contentColumnVisibility}
              setColumnVisibility={setContentColumnVisibility}
              enable={contentMuiData?.columnFilters?.length > 0}
              height="45vh"
              // disableFilter
              disableSorting
              disableFullScreenToggle
              enableRowSelection
              disableFullScreen
              toolbarLeft={(
                <TableHeaderSelectCount
                  select={Object.keys(contentSelection).length}
                  total={contentDataCount}
                />
              )}
            />
          )
        }
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button label={t('back')} click={back} classes="default-button" />
        </div>
        <div className="form-button">
          <Button label={t('continue')} click={submit} classes="success-button" disabled={disable} />
        </div>
      </div>
    </div>
  );
}

ContentsList.propTypes = {
  selectData: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  name: PropTypes.string,
  values: PropTypes.shape({
    contents: PropTypes.arrayOf(),
    playlists: PropTypes.arrayOf(),
    contentsDetails: PropTypes.arrayOf(),
    playlistDetails: PropTypes.arrayOf(),
  }),
  row: PropTypes.shape({
    original: PropTypes.shape({
      name: PropTypes.string,
      file_type: PropTypes.string.isRequired,
      thumbnailSignedUrls: PropTypes.objectOf(),
      contents_list: PropTypes.arrayOf(),
    }),
  }),
};
ContentsList.defaultProps = {
  name: '',
  values: {},
  row: {},
};

export default ContentsList;
