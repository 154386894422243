import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import posTemplate from '../../../utils/api/pos-it-easy';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';

function AuthenticatePopup(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [posToken, setPosToken] = useState('');

  const handleFunction = (name, value) => {
    setPosToken(value);
  };
  const submit = () => {
    setUploading(true);
    setDisable(true);
    posTemplate.addPosToken(posToken)
      .then(() => {
        setUploading(false);
        setDisable(false);
        props?.setAuthOpenModal(false);
        props?.setOpenModal(true);
      })
      .catch((error) => {
        props?.setAuthOpenModal(false);
        if (error?.response?.data?.code === 500) {
          props?.setErrorModal(true);
        }
        setUploading(false);
        setDisable(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
  };

  useEffect(() => {
    if (posToken) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [posToken]);

  return (
    <div className="authenticate-popup">
      <span className="title">
        {t('welcometopositeasy')}
      </span>
      <span className="sub-title">
        {t('enterkeyprovidedbypositeasy')}
      </span>
      <div className="content-body">
        <Input
          icon={' '}
          label={t('code')}
          type="text"
          name="code"
          required
          value={posToken}
          change={handleFunction}
          placeholder=""
        />
        <Button
          label={uploading ? t('authenticating') : t('authenticate')}
          click={submit}
          disabled={disable}
          classes={uploading ? 'success-button loading-btn' : 'success-button'}
          loading={uploading}
        />
      </div>
    </div>
  );
}

AuthenticatePopup.propTypes = {
  setAuthOpenModal: PropTypes.func.isRequired,
  setOpenModal: PropTypes.node.isRequired,
  setErrorModal: PropTypes.node.isRequired,
};

export default AuthenticatePopup;
