import {
  // get,
  post,
  // patch,
  // deletes,
} from './base/index';

export default {
  selfRegisterCompany: (data = {}) => {
    const params = { ...data };
    return post(`${process.env.REACT_APP_API_URL}cms/v1/companies/self-create-company`, { ...params });
  },
};
