/* eslint no-underscore-dangle: 0 */

const selectedTemplate = require('../../../utils/api/templates');

const reload = () => {
  const unloadCallback = (event) => {
    event.preventDefault();
    return '';
  };

  window.addEventListener('beforeunload', unloadCallback);
  return () => window.removeEventListener('beforeunload', unloadCallback);
};

const initializer = async (templateData) => {
  let payload = '';
  if (templateData && Object.keys(templateData).length > 0) {
    localStorage.setItem('templateEditData', JSON.stringify(templateData));
  }
  const localData = localStorage.getItem('templateEditData');

  if (localData) {
    const parsingLocalData = JSON.parse(localData);

    if (Object.keys(templateData).length === 0) {
      payload = [parsingLocalData];
    } else {
      payload = [templateData];
    }
  } else if (templateData && Object.keys(templateData).length > 0) {
    payload = [templateData];
  }
  return payload;
};

const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl?.split(',');
  const mime = arr?.[0]?.match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr?.length;
  const u8arr = new Uint8Array(n);
  while (n > -1) {
    u8arr[n] = bstr?.charCodeAt(n);
    n -= 1;
  }
  return new File([u8arr], filename, { type: mime });
};

const convertSignedUrl = async (url) => {
  const file = dataURLtoFile(url, 'fileupload');
  const localData = JSON.parse(localStorage.getItem('templateEditData'));
  const res = await selectedTemplate.default.updateImageUrl(localData?._id, file);
  return res.data;
};

const Validation = [
  {
    type: 'Bar Display',
    fields: {
      item_name: 26,
      offer_percentage: 3,
      price: 8,
      description: 101,
      desc: 31,
    },
  },
  {
    type: 'Shelf Display',
    fields: {
      mrp_price: 8,
      offer_price: 8,
      name: 26,
      tag: 61,
      description: 81,
    },
  },
  {
    type: 'Birthday',
    fields: {
      header: 26,
      name: 31,
      description: 101,
    },
  },
];

const findValidation = (type, field) => {
  const validated = Validation.map((data) => {
    if (type === data.type) {
      return data.fields[field];
    }
    return null;
  }).filter((value) => value !== null);
  return validated[0];
};

module.exports = {
  reload,
  initializer,
  convertSignedUrl,
  dataURLtoFile,
  findValidation,
};
