/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { postTemplatesList } from '../../../redux/slices/PosTemplateSlice';
import Button from '../../../shared/component/Button';
import posTemplate from '../../../utils/api/pos-it-easy';
import { getCurrentTemplate } from '../../../redux/slices/TemplateSlice';

function SavedTemplatesPopup(props) {
  const [disable, setDisable] = useState(true);
  const [templateData, setTemplateData] = useState(null);
  const [index, setIndex] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const templateDataRef = useRef(templateData);
  const {
    posTemplates,
    status,
  } = useSelector((state) => state?.posTemplate);
  const existingTemplates = () => {
    if (status === 'succeeded' && posTemplates?.length === 0) {
      dispatch(postTemplatesList());
    }
  };

  useEffect(() => {
    existingTemplates();
  }, []);

  const handleClickSelectTemplate = (data, i) => {
    setIndex(i);
    setTemplateData(data);
  };

  const handleClose = () => {
    props?.setOpenModal(false);
  };

  useEffect(() => {
    templateDataRef.current = templateData;
  }, [templateData]);

  const submit = () => {
    setUploading(true);
    setDisable(true);
    posTemplate.getPosInventories()
      .then((res) => {
        if (res) {
          setTemplateData((prevTemplate) => ({
            ...prevTemplate,
            template_contents: {
              items: [...res.data],
            },
          }));
          setTimeout(() => {
            setUploading(false);
            setDisable(false);
            dispatch(getCurrentTemplate(templateDataRef.current));
            navigate(`/admin/templates/edit-content/${templateData?.template_number}`);
          }, 2000);
        }
      })
      .catch((error) => {
        setUploading(false);
        setDisable(false);
        if (error?.response?.data?.code === 500) {
          props?.setOpenModal(false);
          props?.setErrorModal(true);
        }
      });
  };

  useEffect(() => {
    if (templateData) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [templateData]);

  return (
    <div className="saved-templates-popup">
      <div className="template-grid">
        {posTemplates?.map((data, dataIndex) => (
          <div className="grid-item" key={data?._id}>
            <div
              className={index === dataIndex ? 'card active' : 'card'}
              role="none"
              onClick={() => handleClickSelectTemplate(data, dataIndex)}
            >
              <div className="inner-card">
                <img src={data?.template_image_url} alt="" />
              </div>
            </div>
            <span className="name">{data?.template_title}</span>
          </div>
        ))}
      </div>
      <div className="form-button-group">
        <div className="form-button">
          <Button
            label={t('back')}
            click={handleClose}
            classes="default-button"
          />
        </div>

        <div className="form-button">
          <Button
            label={uploading ? t('loading') : t('usetemplate')}
            click={submit}
            disabled={disable}
            classes={uploading ? 'success-button loading-btn' : 'success-button'}
            loading={uploading}
          />
        </div>
      </div>
    </div>
  );
}

SavedTemplatesPopup.propTypes = {
  setOpenModal: PropTypes.node.isRequired,
  setErrorModal: PropTypes.node.isRequired,
};

export default SavedTemplatesPopup;
