import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalBox from '../../shared/component/ModalBox';
import AuthenticatePopup from './components/AuthenticatePopup';
import SavedTemplatesPopup from './components/SavedTemplatesPopup';
import posItEasyLogo from '../../assets/images/Templates/posIteasyLogo.png';
import Button from '../../shared/component/Button';
import InvalidTokenPopup from './components/InvalidTokenPopup';
import posTemplate from '../../utils/api/pos-it-easy';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { checkFeatures, checkPermission } from '../../utils/helpers';

function Integration() {
  const [openModal, setOpenModal] = useState(false);
  const [authOpenModal, setAuthOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [enableGenerate, setEnableGenerate] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (checkPermission('positeasy') && checkFeatures('integration', 'positeasy')) {
      posTemplate.validPosItEasyToken()
        .then((res) => {
          if (res?.data?.result === 'valid') {
            setOpenModal(true);
          } else {
            setAuthOpenModal(true);
          }
        })
        .catch((err) => {
          dispatch(setErrorNotification(err?.response?.data));
        });
    }
  };

  const handleEditClick = () => {
    if (checkPermission('positeasy') && checkFeatures('integration', 'positeasy')) {
      setAuthOpenModal(true);
    }
  };

  const handleChangeRadio = () => {
    if (checkPermission('positeasy') && checkFeatures('integration', 'positeasy')) {
      setEnableGenerate((prev) => !prev);
    }
  };
  return (
    <div>
      <ModalBox
        status={openModal}
        closeModal={setOpenModal}
        modalView={{
          title: t('savedtemplate'),
          content: <SavedTemplatesPopup
            setOpenModal={setOpenModal}
            setErrorModal={setErrorModal}
          />,
        }}
      />
      <ModalBox
        status={authOpenModal}
        closeModal={setAuthOpenModal}
        modalView={{
          content: <AuthenticatePopup
            setAuthOpenModal={setAuthOpenModal}
            setOpenModal={setOpenModal}
            setErrorModal={setErrorModal}
          />,
        }}
      />
      <ModalBox
        status={errorModal}
        closeModal={setErrorModal}
        modalView={{
          content: <InvalidTokenPopup
            setErrorModal={setErrorModal}
            setOpenModal={setOpenModal}
            setAuthOpenModal={setAuthOpenModal}
          />,
        }}
      />
      <div className="integration">
        <div className="title">
          <h1>{t('integration')}</h1>
        </div>
        <div className="integration-grid">
          <div className="grid-item">
            <div className="grid-box">
              <img src={posItEasyLogo} alt="" />
              <Button
                label={t('edit')}
                classes="default-button"
                click={handleEditClick}
                disabled={enableGenerate}
              />
              <Button
                label={t('generate')}
                classes="dark-button"
                click={handleClick}
                disabled={enableGenerate}
              />
            </div>
            <div className="bottom">
              <span className="title">POSitEasy</span>
              <div className="toggle-button">
                <label className="switch" htmlFor="toggle-checkbox">
                  <input
                    type="checkbox"
                    id="toggle-checkbox"
                    checked={enableGenerate}
                    onChange={(handleChangeRadio)}
                  />
                  <span className="slider round" />
                </label>
                <span>{t('disable')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Integration;
