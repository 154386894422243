/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import YouTube from 'react-youtube';
import getYouTubeID from 'get-youtube-id';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import deepEqual from 'deep-equal';

import { Input } from '../../../shared/component/form/Input';
import Button from '../../../shared/component/Button';
import FileUpload from '../../../shared/component/form/FileUpload';
import InputTags from '../../../shared/component/form/InputTags';
import myContent from '../../../utils/api/my-content';

import { updateContent } from '../../../redux/slices/MyContentSlice';
import { setErrorNotification } from '../../../redux/slices/NotificationSlice';
import SelectBox from '../../../shared/component/form/SelectBox';
import ModalBox from '../../../shared/component/ModalBox';
import Success from '../../../shared/component/Success';

import generateVideoThumbnails from './index';
import {
  RightArrow,
  UrlLinkIcon,
  VolumeMuteIcon,
  VolumeUnmuteIcon,
} from '../../../shared/component/svgIcon';
import SliderComponent from '../../../shared/component/form/SliderInput';
import {
  checkFeatures,
  checkPermission,
  contentUploadImageResize,
} from '../../../utils/helpers';
import {
  descriptionValidation,
  integerValidation,
  nameValidation,
  tagValidation,
  urlValidation,
  youtubeUrlValidation,
} from '../../../shared/component/form/Validation';

function ContentEdit(props) {
  const { t } = useTranslation();
  const orientation = [
    {
      id: 1,
      label: 'Portrait',
    },
    {
      id: 2,
      label: 'Landscape',
    },
  ];
  const transitions = [
    {
      id: 1,
      label: 'FadeIn',
    },
    {
      id: 2,
      label: 'FadeOut',
    },
    {
      id: 3,
      label: 'SlideInUp',
    },
    {
      id: 4,
      label: 'SlideInDown',
    },
    {
      id: 5,
      label: 'SlideInRight',
    },
    {
      id: 6,
      label: 'SlideInLeft',
    },
    {
      id: 7,
      label: 'SlideOutUp',
    },
    {
      id: 8,
      label: 'SlideOutDown',
    },
    {
      id: 9,
      label: 'SlideOutLeft',
    },
    {
      id: 10,
      label: 'SlideOutRight',
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disable, setDisable] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [notification, setNotification] = useState(false);
  const [value, setValue] = useState({
    ...props?.contentData,
    orientation: props?.contentData?.orientation || 'Landscape',
    start_transition: props?.contentData?.start_transition || 'FadeIn',
    end_transition: props?.contentData?.end_transition || 'FadeOut',
  });
  const originalValue = props?.contentData;
  const [image, setImage] = useState(props?.contentData?.signedUrl);
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState();
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [advanceSetting, setAdvanceSetting] = useState();

  const getThumbnailImage = async (videoFile) => {
    setSkeletonLoader(true);
    generateVideoThumbnails(videoFile, 5)
      .then((thumbnailArray) => {
        setSkeletonLoader(false);
        setThumbnails(thumbnailArray?.res);
        setSelectedThumbnail(thumbnailArray?.res?.[0]);
      }).catch((err) => {
        setSkeletonLoader(false);
        console.error(err);
      });
  };

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const handleFunction = async (name, changeValue, key = 0) => {
    if (name === 'tags' && key === 13) {
      setValue({ ...value, [name]: Array.isArray(changeValue) ? changeValue : [...value.tags, changeValue] });
    } else if (
      (name === 'orientation'
        || name === 'start_transition'
        || name === 'end_transition')
      && changeValue
    ) {
      setValue({ ...value, [name]: changeValue.label });
    } else if (name === 'file') {
      if (changeValue) {
        setImage(URL.createObjectURL(changeValue));
        if (changeValue.type === 'video/mp4') {
          getThumbnailImage(changeValue);
          setValue({ ...value, [name]: changeValue, file_type: changeValue.type });
        }
        setValue({ ...value, [name]: changeValue, file_type: changeValue.type });
      } else {
        setThumbnails([]);
        setSelectedThumbnail('');
        setValue({ ...value, [name]: changeValue });
      }
    } else if (
      name === 'name'
      || name === 'description'
      || name === 'duration'
      || name === 'thumbnail'
      || name === 'volume'
      || name === 'url'
    ) {
      setValue({ ...value, [name]: changeValue });
    }
  };

  function dataURLtoFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleViewPdf = () => {
    const newTab = window.open(image, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      dispatch(setErrorNotification({ message: 'Something went wrong.' }));
    }
  };

  const submit = async () => {
    if (nameValidation(1, 50, value?.name)
      && descriptionValidation(500, value?.description)
      && ((urlValidation(value?.url) || youtubeUrlValidation(value?.url))
        || (value?.file_type !== 'url' && value?.file_type !== 'youtube'))
      && tagValidation(30, 10, value?.tags)
      && (integerValidation(1, Infinity, value?.duration)
        || (value?.file_type?.includes('video') || value?.file_type === 'youtube'))
    ) {
      const request = {
        tags: value?.tags,
        name: value?.name,
        orientation: value?.orientation,
        description: value?.description,
      };
      if (value?.file?.type.includes('video') || value?.file_type.includes('video')) {
        request.file = value.file;
        request.volume = value?.volume;
        request.start_transition = value?.start_transition;
        request.end_transition = value?.end_transition;
        if (value?.file) {
          request.thumbnail = selectedThumbnail
            ? {
              px_600x400: dataURLtoFile(selectedThumbnail, value?.name),
              px_50x50: dataURLtoFile(selectedThumbnail, value?.name),
            }
            : undefined;
        }
      } else if (value?.file?.type.includes('image') || value?.file_type.includes('image')) {
        // else execute when the selected file is an image
        request.duration = value?.duration;
        request.start_transition = value?.start_transition;
        request.end_transition = value?.end_transition;
        if (value?.file) {
          const imageResize = await contentUploadImageResize(URL.createObjectURL(value.file));
          request.content_images = {
            px_7680x4320: imageResize?.px_7680x4320 && dataURLtoFile(imageResize?.px_7680x4320, value?.name),
            px_3840x2160: imageResize?.px_3840x2160 && dataURLtoFile(imageResize?.px_3840x2160, value?.name),
            px_2048x1080: imageResize?.px_2048x1080 && dataURLtoFile(imageResize?.px_2048x1080, value?.name),
            px_1920x1080: imageResize?.px_1920x1080 && dataURLtoFile(imageResize?.px_1920x1080, value?.name),
            px_1280x720: imageResize?.px_1280x720 && dataURLtoFile(imageResize?.px_1280x720, value?.name),
          };
          request.thumbnail = {
            px_600x400: imageResize?.px_600x400 && dataURLtoFile(imageResize?.px_600x400, value?.name),
            px_50x50: imageResize?.px_50x50 && dataURLtoFile(imageResize?.px_50x50, value?.name),
          };
        }
      } else if (value?.file?.type.includes('pdf') || value?.file_type.includes('pdf')) {
        // else execute when the selected file is an image
        request.file = value.file;
        request.duration = value?.duration;
        request.start_transition = value?.start_transition;
        request.end_transition = value?.end_transition;
      } else if (value?.file_type === 'url') {
        request.duration = value?.duration;
        request.url = value.url;
        request.file_type = value?.file_type;
        request.start_transition = value?.start_transition;
        request.end_transition = value?.end_transition;
      } else if (value?.file_type === 'youtube') {
        request.url = value.url;
        request.file_type = value?.file_type;
        request.start_transition = value?.start_transition;
        request.end_transition = value?.end_transition;
      }

      setDisable(true);
      setUploading(true);
      myContent.updateContent(request, value.id).then((res) => {
        dispatch(updateContent(res?.data));
        setUploading(false);
        setUploadMessage(t('contentUpdatedSuccess'));
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
          setDisable(false);
          props?.refresh();
          props?.close(false);
        }, 3000);
      }).catch((error) => {
        setUploading(false);
        dispatch(setErrorNotification(error?.response?.data));
      });
    }
  };

  useEffect(() => {
    if (value.file && value.file.type === 'video/mp4') {
      getThumbnailImage(value.file);
    }
  }, [navigate]);

  useEffect(() => {
    const isVideo = value?.file_type?.includes('video') && image;
    const isImageOrPDF = (
      value?.file_type?.includes('image')
      || value?.file_type?.includes('pdf')
    ) && image && value?.duration > 0;
    const isURL = value?.file_type?.includes('url')
      && value?.url
      && value?.duration > 0;
    const isYoutube = value?.file_type?.includes('youtube') && value?.url;

    if (
      value.name
      && value.orientation
      && value.start_transition
      && value.end_transition
      && (isVideo || isImageOrPDF || isYoutube || isURL)
      && !deepEqual(value, originalValue)
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [value]);

  return (
    <div className="upload-media">
      <ModalBox
        status={notification}
        closeModal={setNotification}
        notification
        modalView={{ content: <Success message={uploadMessage} /> }}
      />
      <div className="form">
        {(props?.contentData?.file_type?.includes('video')
          || props?.contentData?.file_type?.includes('image')
          || props?.contentData?.file_type?.includes('pdf'))
          && (
            <>
              <FileUpload
                icon={' '}
                label={t('content')}
                name="file"
                setImgData={setImage}
                imgData={image}
                setPicture={handleFunction}
                type={value?.file_type}
                thumbnail={selectedThumbnail || value?.thumbnailSignedUrl}
                required
              />
              {skeletonLoader && (
                <div className="thumbnail-container skeleton-loader">
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </div>
              )}
              <div className="thumbnail-container">
                {thumbnails && thumbnails.map((item) => (
                  <img
                    key={item}
                    src={item}
                    alt="thumbnail"
                    role="presentation"
                    onClick={() => setSelectedThumbnail(item)}
                    className={selectedThumbnail === item ? 'thumbnail-image selectedThumbnail' : 'thumbnail-image'}
                  />
                ))}
              </div>
            </>
          )}
        {(props?.contentData?.file_type === 'url' || props?.contentData?.file_type === 'youtube')
          && (
            <div className="url-image-preview-container">
              <p>Preview</p>
              {props?.contentData?.file_type === 'youtube' ? (
                <YouTube
                  videoId={getYouTubeID(value?.url)}
                  opts={opts}
                  className="youtube-video"
                  onReady={(e) => e.target.pauseVideo()}
                />
              ) : (
                <div className="url-image">
                  {value?.url
                    ? (
                      <iframe
                        src={value?.url}
                        title="url"
                      />
                    ) : (
                      <UrlLinkIcon />
                    )}
                </div>
              )}
            </div>
          )}
        {(props?.contentData?.file_type === 'url' || props?.contentData?.file_type === 'youtube')
          && (
            <div>
              <Input
                icon={' '}
                label={t('URL')}
                type="text"
                name="url"
                required
                value={value?.url}
                change={handleFunction}
                placeholder={t('Enter URL')}
                error={!urlValidation(value?.url) && !youtubeUrlValidation(value?.url)}
                errorMessage={t('urlValidation')}
              />
            </div>
          )}
        <div className="form-fields">
          <div className="form-fields-row">
            <Input
              icon={' '}
              label={t('contentName')}
              type="text"
              name="name"
              required
              value={value?.name}
              change={handleFunction}
              placeholder={t('enterContentName')}
              error={!nameValidation(1, 50, value?.name)}
              errorMessage={t('contentNameValidation')}
            />
            <SelectBox
              label={t('orientation')}
              name="orientation"
              lists={orientation}
              value={value?.orientation}
              onchange={handleFunction}
              placeholder={t('selectOrientation')}
              id="orientation"
            />
          </div>
          <Input
            icon={' '}
            label={t('contentDescription')}
            name="description"
            value={value?.description}
            change={handleFunction}
            placeholder={t('enterContentDescription')}
            error={!descriptionValidation(500, value?.description)}
            errorMessage={t('descriptionValidation')}
          />
          <InputTags
            icon={' '}
            label={t('mediaTags')}
            name="tags"
            addData={handleFunction}
            required={false}
            value={value?.tags}
            placeholder={t('enterMediaTags')}
            error={!tagValidation(30, 10, value?.tags)}
            errorMessage={t('tagsValidation')}
          />
        </div>
        <div className="advance-settings-wrap">
          <div
            role="presentation"
            onClick={() => setAdvanceSetting(!advanceSetting)}
            className="advance-header"
          >
            <h2>Advanced Settings</h2>
            <span
              style={{
                transform: advanceSetting ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            >
              <RightArrow />
            </span>
          </div>
          <div
            className="settings-input"
            style={{
              height: advanceSetting ? '185px' : '0px',
            }}
          >
            <div className="single-row">
              <SelectBox
                label={t('startTransition')}
                name="start_transition"
                lists={transitions}
                value={value?.start_transition}
                onchange={handleFunction}
                placeholder={t('selectTransitions')}
                id="start_transitions"
              />
              <SelectBox
                label={t('endTransition')}
                name="end_transition"
                lists={transitions}
                value={value?.end_transition}
                onchange={handleFunction}
                placeholder={t('selectTransitions')}
                id="end_transitions"
              />
            </div>
            <div className="single-row">
              {
                (() => {
                  let optionalField;
                  if (value?.file_type?.includes('video')
                    && checkFeatures('content', 'volume_controller')
                    && checkPermission('volumeControllerContent')
                  ) {
                    optionalField = (
                      <SliderComponent
                        label={t('volume')}
                        name="volume"
                        icon={
                          value?.volume > 0
                            ? <VolumeUnmuteIcon />
                            : <VolumeMuteIcon />
                        }
                        value={value?.volume}
                        changeFunc={handleFunction}
                      />
                    );
                  } else if (!value?.file_type?.includes('youtube') && !value?.file_type?.includes('video')) {
                    optionalField = (
                      <Input
                        icon={' '}
                        label={t('durationSecond')}
                        type="number"
                        name="duration"
                        required
                        value={value?.duration}
                        change={handleFunction}
                        placeholder={t('enterDuration')}
                        error={!integerValidation(1, Infinity, value?.duration)}
                        errorMessage={t('durationValidation')}
                      />
                    );
                  }
                  return optionalField;
                })()
              }
            </div>
          </div>
        </div>
        <div className="form-button-group">
          <div className="form-button">
            <Button
              label={t('back')}
              click={() => props?.close(false)}
              classes="default-button"
            />
          </div>
          {value?.file_type?.includes('pdf')
            && (
              <div className="form-button">
                <Button
                  label={t('preview')}
                  click={handleViewPdf}
                  classes="default-button"
                />
              </div>
            )}
          <div className="form-button">
            <Button
              label={uploading ? t('uploading') : t('upload')}
              click={submit}
              classes={uploading ? 'success-button loading-btn' : 'success-button'}
              disabled={disable}
              loading={uploading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ContentEdit.propTypes = {
  contentData: PropTypes.shape(),
  close: PropTypes.func.isRequired,
  refresh: PropTypes.objectOf.isRequired,
};

ContentEdit.defaultProps = {
  contentData: {},
};

export default ContentEdit;
