/* eslint no-underscore-dangle: 0 */
import React, { useEffect, useState } from 'react';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import LayoutHeader from './LayoutHeader';
import ContentList from './ContentList';
import DroppableContainer from './DropableContainer';
import { setErrorNotification } from '../../../../redux/slices/NotificationSlice';
import { addNewSchedule } from '../../../../redux/slices/ScheduleSlice';

function LayoutSchedule() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { newSchedule } = useSelector((state) => state.schedules);

  const [availableLayouts, setAvailableLayouts] = useState([]);

  const [activeId, setActiveId] = useState(null);
  const [dropOverContainer, setDropOverContainer] = useState('');
  const [selectedTab, setSelectedTab] = useState('content');
  // DND Handlers
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = (event) => {
    setActiveId(event?.active?.data?.current?.id);
  };

  const handleDragMove = (event) => {
    setDropOverContainer(event?.over?.id);
  };

  const handleDragEnd = (event) => {
    const droppedContent = { ...event?.active?.data?.current };
    const droppedLayoutId = event?.over?.id;

    setAvailableLayouts((preValue) => preValue.map((layout) => {
      if (layout?._id === droppedLayoutId) {
        if (layout?.file_types?.some((contentType) => droppedContent?.file_type
          ?.includes(contentType))) {
          setActiveId(null);
          // Create a new object with updated contents to maintain immutability
          return {
            ...layout,
            contents: [
              ...(layout.contents || []),
              droppedContent,
            ],
          };
        }

        dispatch(setErrorNotification({
          message: `Only ${layout?.file_types?.map((type) => type)?.join(', ')} are allowed 
            in this section`,
        }));
      }
      return layout;
    }));

    setDropOverContainer('');
  };

  const handleSubmitContentLayout = () => {
    const selectedLayouts = availableLayouts?.every((layout) => layout.contents.length);
    // check if all layouts are selected
    if (selectedLayouts) {
      if (newSchedule?.type === 'scheduleAdd') {
        dispatch(addNewSchedule({
          ...newSchedule,
          schedule: {
            ...newSchedule?.schedule,
            layouts: {
              ...newSchedule?.schedule?.entireLayout,
              layouts: availableLayouts,
            },
          },
        }));
        navigate('../admin/schedules/add');
      } else if (newSchedule?.type === 'scheduleEdit') {
        dispatch(addNewSchedule({
          ...newSchedule,
          layouts: {
            ...newSchedule?.entireLayout,
            layouts: availableLayouts,
          },
        }));
        navigate(`../admin/schedules/edit/${newSchedule?.id}?type=edit`);
      } else if (newSchedule?.type === 'scheduleClone') {
        dispatch(addNewSchedule({
          ...newSchedule,
          layouts: {
            ...newSchedule?.entireLayout,
            layouts: availableLayouts,
          },
        }));
        navigate(`../admin/schedules/edit/${newSchedule?.id}?type=clone`);
      }
    } else {
      dispatch(setErrorNotification({ message: 'Content type is required' }));
    }
  };

  useEffect(() => {
    if (newSchedule?.type === 'scheduleAdd') {
      setAvailableLayouts(newSchedule?.schedule?.entireLayout?.layouts?.map((eachLayout) => {
        let finalStructure = eachLayout;
        if (eachLayout?.file_types?.[0] === 'weather'
          || eachLayout?.file_types?.[0] === 'clock'
          || eachLayout?.file_types?.[0] === 'rss') {
          finalStructure = {
            ...eachLayout,
            selectedWidget: eachLayout?.contents?.[0],
            contents: [eachLayout?.contents?.[0]?._id],
          };
        }
        return finalStructure;
      }));
    } else if (newSchedule?.type === 'scheduleEdit' || newSchedule?.type === 'scheduleClone') {
      setAvailableLayouts(newSchedule?.entireLayout?.layouts?.map((eachLayout) => {
        let finalStructure = eachLayout;
        if (eachLayout?.file_types?.[0] === 'weather'
          || eachLayout?.file_types?.[0] === 'clock'
          || eachLayout?.file_types?.[0] === 'rss') {
          finalStructure = {
            ...eachLayout,
            selectedWidget: eachLayout?.contents?.[0],
            contents: [eachLayout?.contents?.[0]?._id],
          };
        }
        return finalStructure;
      }));
    }
  }, []);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
    >
      <div className="layout-schedule-wrap">
        <LayoutHeader
          type={newSchedule?.type}
          newSchedule={newSchedule}
          handleSubmitContentLayout={handleSubmitContentLayout}
        />
        <div className="layout-schedule-container">
          <div className="layout-contents">
            <ContentList
              activeId={activeId}
              setActiveId={setActiveId}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
          <div className="layout-wrap">
            <h1>Drag & Drop contents in grids</h1>
            <div className="layout-container">
              {availableLayouts?.map((layout, index) => (
                <DroppableContainer
                  layout={layout}
                  gridCount={index}
                  dropOverContainer={dropOverContainer}
                  setAvailableLayouts={setAvailableLayouts}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </DndContext>
  );
}

export default LayoutSchedule;
