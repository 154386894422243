import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TopContent from '../../shared/component/TopContent';
import { DeleteIcon, PlusIcon, UploadIcon } from '../../shared/component/svgIcon';
import ModalBox from '../../shared/component/ModalBox';
import Success from '../../shared/component/Success';
import Button from '../../shared/component/Button';
import { fetchPreferenceList } from '../../redux/slices/AccountPreferenceSlice';
import accountPreference from '../../utils/api/account-preference';
import { setErrorNotification } from '../../redux/slices/NotificationSlice';
import { uploadImageResizeSingle } from '../../utils/helpers';

function AccountPreference() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { preferenceList } = useSelector((state) => state.accountPreference);
  const [selectedImage, setSelectedImage] = useState({
    url: null,
    imageUrl: '',
  });
  const [disable, setDisable] = useState(true);
  const [buttonStatus, setButtonStatus] = useState('');

  const backButton = () => {
    navigate('../');
  };

  const handleImageChange = async (e) => {
    const formData = new FormData();
    const files = Array.from(e.target?.files); // Convert FileList to an array
    // This function resizes images to 8K resolution if their original dimensions exceed 8K
    const processedFiles = await Promise.all(
      files.map((file) => uploadImageResizeSingle(file)),
    );
    const maxFileSizeInMB = 5;
    const maxFileSizeInBytes = maxFileSizeInMB * 1024 * 1024; // Convert MB to bytes

    if (processedFiles?.length > 5) {
      dispatch(setErrorNotification({ message: 'Maximum 5 files should be uploaded at once.' }));
    } else if (processedFiles?.some((file) => file.size > maxFileSizeInBytes)) {
      dispatch(setErrorNotification({ message: `File size should be less than ${maxFileSizeInMB} MB` }));
    } else {
      // Loop through each file and append it to formData
      processedFiles.forEach((file) => {
        formData.append('player_app_background_images', file);
      });

      if (formData) {
        accountPreference.addPreferenceList(formData)
          .then(() => dispatch(fetchPreferenceList()))
          .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
      }
    }
  };

  const submit = () => {
    setButtonStatus('loading');
    accountPreference.updatePreference({ imageUrl: selectedImage.url })
      .then(() => {
        setButtonStatus('success');
        setTimeout(() => {
          dispatch(fetchPreferenceList());
          setButtonStatus(false);
        }, 3000);
      })
      .catch((err) => {
        dispatch(setErrorNotification(err?.response?.data));
        setButtonStatus(false);
      });
  };

  const handleSelectedImage = (item, url) => {
    setSelectedImage({
      url,
      imageUrl: item,
    });
  };

  const handleDeleteImage = () => {
    accountPreference.deletePreference({ imageUrl: selectedImage?.url })
      .then(() => {
        dispatch(fetchPreferenceList());
        setSelectedImage({});
        setDisable(true);
      })
      .catch((err) => dispatch(setErrorNotification(err?.response?.data)));
  };

  useEffect(() => {
    if (preferenceList.length > 0) {
      const backgroundIndex = preferenceList.findIndex((each) => each.is_active === true);
      setSelectedImage({
        url: backgroundIndex !== -1 ? preferenceList[backgroundIndex].url : null,
        imageUrl: backgroundIndex !== -1 ? preferenceList[backgroundIndex].signedUrl : null,
      });
    }
  }, [preferenceList]);

  useEffect(() => {
    if (selectedImage?.imageUrl) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [selectedImage]);

  useEffect(() => {
    dispatch(fetchPreferenceList());
  }, []);

  return (
    <div className="content">
      <ModalBox
        status={buttonStatus === 'success'}
        closeModal={setButtonStatus}
        notification
        modalView={{ content: <Success message={t('backgroundImageUploadSuccess')} /> }}
      />
      <div className="main-content">
        <TopContent
          label={t('preferences')}
          click={backButton}
        />
        <div className="content-area">
          <div className="preference-wrap">
            <div className="preference-content">
              <h1>{t('backgroundImage')}</h1>
              <p>{t('uploadImageEnjoyContentMessage')}</p>
            </div>
            <div className="preference-image-wrap">
              <div className="upload-wrap">
                {!selectedImage?.imageUrl
                  ? (
                    <div className="upload-container">
                      <label className="upload-text" htmlFor="upload-image">
                        <UploadIcon />
                        <h2>{t('clickOrBrowseToUploadFilesFromYourDevice')}</h2>
                        <p>{t('supportedFormats')}</p>
                      </label>
                      <input
                        id="upload-image"
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleImageChange}
                        multiple
                        style={{ display: 'none' }}
                      />
                    </div>
                  ) : (
                    <div className="image-wrap">
                      <div
                        className="delete-container"
                        role="presentation"
                        onClick={handleDeleteImage}
                      >
                        <DeleteIcon />
                      </div>
                      <img
                        src={selectedImage?.imageUrl || ''}
                        alt="account-preference"
                        className="display-image"
                      />
                    </div>
                  )}
              </div>
              <div className="display-images-wrap">
                {preferenceList?.length > 0
                  && (
                    <label className="upload-box" htmlFor="upload-image">
                      <PlusIcon />
                    </label>
                  )}
                <input
                  id="upload-image"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleImageChange}
                  multiple
                  style={{ display: 'none' }}
                />
                {preferenceList?.map((item) => (
                  <img
                    src={item?.signedUrl}
                    alt="uploadedImage"
                    className="selected-image"
                    role="presentation"
                    onClick={() => handleSelectedImage(item?.signedUrl, item?.url)}
                  />
                ))}
              </div>
              <div className="form-button-group">
                <div className="form-button">
                  <Button
                    label={t('setBackgroundImage')}
                    disabled={disable}
                    click={submit}
                    classes={buttonStatus === 'loading' ? 'success-button loading-btn' : 'success-button'}
                    loading={buttonStatus === 'loading'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountPreference;
