/* eslint-disable no-param-reassign */
/* eslint no-underscore-dangle: 0 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import myContent from '../../utils/api/my-content';
import {
  convertBytes,
  changeDateTimeFormat,
  addImageType,
  statusFileType,
} from '../../utils/helpers';
import { setErrorNotification } from './NotificationSlice';

export const fetchContentList = createAsyncThunk(
  'contents/fetchContents',
  async (data, { dispatch }) => {
    const response = await myContent.getContents(data).then((response1) => {
      console.log(response1);
      const tableValues = response1?.data?.results.map((val) => ({
        ...val,
        author: val?.createdBy?.name,
        file_size: val.file_size ? convertBytes(val.file_size) : '0 KB',
        createDate: changeDateTimeFormat(val.createdAt),
        updateDate: changeDateTimeFormat(val.updatedAt),
        file_type_to_show: val.file_type ? addImageType(val.file_type) : '',
        status: val?.file_type ? statusFileType(val?.file_type, val?.template_content?.length > 0) : '',
        template_content: val?.template_content ? val?.template_content : null,
        id: val?._id,
      }));
      return { ...response1?.data, results: tableValues, code: 200 };
    }).catch((error) => dispatch(setErrorNotification(error?.response?.data)));
    return response;
  },
);

const initialState = {
  contents: [],
  pageCount: 0,
  totalPageCount: 1,
  totalDataCount: 0,
  status: 'succeeded',
  createRequest: '', // Status of add content request
  filter: {},
  tableFilter: {},
};

export const myContentSlice = createSlice({
  name: 'myContent',
  initialState,
  reducers: {
    // Reducer to add the new content to top of the content list
    createNewContent: (state, action) => {
      const newContent = action.payload;
      const contentList = [
        {
          ...newContent,
          author: newContent.createdBy?.name,
          file_size: convertBytes(newContent?.file_size) || '0 KB',
          createDate: changeDateTimeFormat(newContent?.createdAt),
          updateDate: changeDateTimeFormat(newContent?.updatedAt),
          file_type_to_show: addImageType(newContent?.file_type),
          status: statusFileType(newContent?.file_type, newContent?.template_content?.length > 0),
          template_content: newContent?.template_content ? newContent?.template_content : null,
          id: newContent._id,
        },
        ...state.contents, // Existing contents
      ];
      state.contents = contentList; // update the content list
    },
    // Reducer to update the content by ID.
    updateContent: (state, action) => {
      const updatedContent = action.payload; // content to be added at the top
      const contentList = state.contents; // Assign the existing content to another variable
      // Find the index of the updated content by ID
      const index = contentList.findIndex((content) => content._id === updatedContent._id);
      // If the index found, replace the entire data
      if (index !== -1) {
        contentList[index] = {
          ...updatedContent,
          author: updatedContent.createdBy?.name,
          file_size: convertBytes(updatedContent.file_size) || '0 KB',
          createDate: changeDateTimeFormat(updatedContent.createdAt),
          updateDate: changeDateTimeFormat(updatedContent.updatedAt),
          status: statusFileType(updatedContent?.file_type),
          template_content: updatedContent.template_content,
          file_type_to_show: addImageType(updatedContent?.file_type),
          id: updatedContent._id,

        };
      }
      state.contents = contentList; // Finally update the content list
    },
    // Reducer to delete the content by ID
    deleteContent: (state, action) => {
      const contentList = state.contents;

      // Find the index to delete by ID
      const index = contentList.findIndex((content) => content.id === action.payload);
      // If the index found, remove that index from list of contents
      if (index !== -1) {
        contentList.splice(index, 1);
      }
      state.contents = contentList; // Update the contents state after deleted
    },
    showErrorInContent: (state, action) => {
      state.error = action.payload;
    },
    setCount: (state) => {
      state.pageCount = 1;
      state.totalPageCount = 1;
    },
    setTableFilter: (state, action) => {
      state.tableFilter = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    resetContentState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed

    builder
      .addCase(fetchContentList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContentList.fulfilled, (state, action) => {
        // Add user to the state array
        if (action.payload.code === 200) {
          const fetchedData = action.payload.results;
          state.pageCount = action.payload.page;
          state.totalPageCount = action.payload.totalPages === 0 ? 1 : action.payload.totalPages;
          state.totalDataCount = action.payload.totalResults;
          if (action.payload.page === 1) {
            state.contents = fetchedData;
          } else {
            const finalData = [...state.contents];
            // Add fetchedData to finalData, but only if the id is not already present
            fetchedData.forEach((obj) => {
              if (!finalData.some((item) => item._id === obj._id)) {
                finalData.push(obj);
              }
            });
            state.contents = finalData;
          }
        }
        state.status = 'succeeded';
      })
      .addCase(fetchContentList.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  createNewContent,
  updateContent,
  deleteContent,
  setCount,
  setFilter,
  setTableFilter,
  resetContentState,
} = myContentSlice.actions;

export default myContentSlice.reducer;
